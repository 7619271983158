import remote from '../remote'

const state = () => {
    return {
        list: []
    }
}

const getters = {
    getOrders(state){
        return state.list
    }
}

const mutations = {
    setOrdersList(state, list){
        state.list = list
    }
}

const actions = {
    async fetchOrders({rootState, dispatch, commit}){
        let { data, error } = await remote.rpc('list_orders')
        if(error == null){
            commit('setOrdersList', data)
        }
    },
    async setOrder({rootState, dispatch, commit}, new_order){
        let { data, error } = await remote.rpc('set_order', { json_args: new_order })
        if(error == null){
            dispatch('fetchOrders')
        }
    }
}

export default {
    state, getters, mutations, actions
}