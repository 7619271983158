<template>
    <section class="container-fluid">
        <div class="row h-100 align-items-center">
            <div v-if="!confirm" class="col-12 col-md-6 offset-md-3">
                <h2>Sign Up</h2>
                <LoginForm :confirm_password="true" @submit="signUp" />
                <div class="text-danger" v-if="error"><h5>{{error.title}}</h5>{{error.message}}</div>
            </div>
            <div v-else class="col-12 col-md-6 offset-md-3">
                <h2>Confirmation needed</h2>
                <p>Please click the link in the confirmation email sent to you</p>
            </div>
        </div>
    </section>
</template>

<script>
import LoginForm from './LoginForm'

export default {
    data(){
        return {
            subscription: null,
            error: false,
            confirm: false
        }
    },
    computed: {
        signedIn: function(){
            return this.$store.getters.isSignedIn
        },
        logs: function(){
            return this.$store.getters.getUnseenLogs('error', 'auth')
        }
    },
    watch: {
        logs: function(_new, _old){
            if(_new.length > 0){
                this.error = _new[0]
                this.$store.dispatch('logShown', this.error.id)
            }
        }
    },
    components: {
        LoginForm
    },
    methods: {
        signUp: function(email, password){
            this.$store
                .dispatch('signUp', {email, password})
                .then(()=>{
                    this.confirm = true
                })
        }
    },
    beforeMount(){

        const goBack = () => { this.$router.push('/') }
        if(this.signedIn){ goBack() }
        this.subscription = this.$store.subscribe((mutation, state) => {
            if(state.auth.signedIn){
                goBack()
            }
        })
    },
    beforeUnmount(){
    }
}
</script>

<style></style>