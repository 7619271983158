export default {
    'sponsor': 'Sponsor',
    'name': 'Name',
    'date_label': 'Date (text)',
    'id': 'ID',
    'order_id': 'Order #',
    'date': 'Date',
    'event_type': 'Event type',
    'listings': 'Listings',
    'on_july': 'meeting in July',
    'on_august': 'meeting in August',
    'on_holiday': 'meeting in Holiday',
    'details': 'Details',
    'sponsor': 'Sponsor',
    'family_name': 'Family Name',
    'canvasser': 'Canvasser',
    'first_name': 'First Name',
    'family_card_id': 'Family Card #',
    'canvasser_id': 'Canvasser #',
    'family_id': 'Family #',
    'address': 'Address',
    'city_prov_code': 'City, Province, Code',
    'home_tel': 'Home Tel',
    'work_tel': 'Work Tel',
    'delivery': 'Delivery',
    'order_id': 'Order #',
    'qty': 'Qty',
    'price': 'Price',
    'taxes': 'Taxes',
    'sponsor_id': 'Sponsor #'
}