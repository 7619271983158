export const rpc_calls = {
    set_family_card: ['order', 'family', 'family_card', 'family_events'],
    set_calendar_event: ['order', 'calendar_events']
}
export const actions = {
    set_family_card: 'setFamilyCard',
    set_calendar_event: 'setCalendarEvents'
}
export const fields = {
    sponsor: {
        name:'string',
        email:'string',
        //details: 'object',
        address: 'multiselect',
        billing_address: 'multiselect',
        //billing_details: 'string'
    },
    family: {
        family_id: 'number', 
        family_name: 'string',
        //family_details: 'string'
    },
    canvasser: {
        name:'string',
        email:'string',
        //details: 'object',
        sponsor: 'string',
        sponsor_id: 'number' 
    },
    order: {
        //details: 'object',
        id: 'number',
        sponsor_id: 'number',
        sponsor: 'string',
        price: 'number',
        taxes: 'number'
    },
    calendar_events: {
        id: 'number',
        name: 'string',
        date: "date",
        on_july: 'boolean',
        on_august: 'boolean',
        on_holiday: 'boolean',
        //details: 'object',
        listings: 'number',
        public: 'boolean'//,
        //active: 'boolean'
    },
    family_events: {
        //member_id: 'number',
        name: 'string',
        date: "date",
        type: 'string',
        //details: 'object',
        public: 'boolean',
        //active: 'boolean',
        //email: 'string'
    },
    //family_member: {
    //    member_id: 'number',
    //    name: 'string',
    //    //details: 'object',
    //    email: 'string'
    //},
    family_card: {
        canvasser_id: 'number',
        canvasser: 'string',
		first_name:'string',
		address:'multiselect',
		city_prov_code:'multiselect',
		home_tel:'string',
		work_tel:'string',
		delivery:'number',
		qty:'number',
		price:'number',
		taxes:'number'
    }
}
export const defaults = {
    sponsor: {
        name:['sponsor', 'sponsor_name'],
        email:['email', 'e-mail', 'mail'],
        //details: 'object',
        address: ['address'],
        billing_address: ['billing_address', 'billing address'],
        //billing_details: 'string'
    },
    family: {
        family_id: ['family#', 'family #', 'family_id', 'family id'], 
        family_name: ['family name', 'family_name', 'family'],
        //family_details: 'string'
    },
    canvasser: {
        name: ['canvasser', 'canvasser_name'],
        email: ['email', 'e-mail', 'mail'],
        //details: 'object',
        sponsor: ['sponsor'],
        sponsor_id: ['sponsor#', 'sponsor #', 'sponsor id', 'sponsor_id'] 
    },
    order: {
        //details: 'object',
        id: ['# order', 'order#', 'order #', 'order', 'order_id', 'order id'],
        sponsor_id: ['sponsor#', 'sponsor #', 'sponsor id', 'sponsor_id'],
        sponsor: ['sponsor'],
        price: ['price'],
        taxes: ['taxes']
    },
    calendar_events: {
        id: ['event id', 'event_id'],
        name: ['Listing', 'name', 'event_name'],
        date: ['Meeting Falls On', 'date', 'event_date'],
        on_july: ['Will Your Group Meet: July?', 'July?', 'july'],
        on_august: ['Will Your Group Meet: August?', 'August?', 'august?', 'august'],
        on_holiday: ['Will Your Group Meet On Any Public/Stat Holidays? ', 'Holidays?', 'holiday?', 'holiday'],
        //details: 'object',
        listings: ['Total Listings', 'listings', 'listing'],
        public: ['public']//,
        //active: ['active']
    },
    family_events: {
        //member_id: ['member id', 'member_id'],
        name: ['name', 'Name', 'full name', 'full_name', 'event_name'],
        date: ['date', 'event_date'],
        type: ['type', 'event_type'],
        //details: 'object',
        public: ['public']//,
        //active: ['active'],
        //email: ['email', 'e-mail', 'mail']
    },
    //family_member: {
    //    member_id: 'number',
    //    name: 'string',
    //    //details: 'object',
    //    email: 'string'
    //},
    family_card: {
        canvasser_id: ['canvasser id', 'canvasser_id'],
        canvasser: ['canvasser'],
		first_name: ['first name', 'first_name'],
		address: ['address street number', 'address street', 'address'],
		city_prov_code: [
            'address city', 'address_city', 'city', 'city_prov_code',
            'address province', 'address_province', 'prov',
            'address postal code', 'address_postal_code', 'postal code', 'postal_code', 'code', 'zip'],
		home_tel: ['home tel', 'home_tel'],
		work_tel: ['work_tel', 'work tel'],
		delivery: ['delivery'],
		qty: ['qty', 'calendar qty', 'calendar quantity', 'Calendar Qty'],
		price: ['price'],
		taxes: ['taxes']
    }
}
export const default_group_field = {
    set_family_card: 'family_events'
}
export const default_group_by = {
    family_events: ['order,sponsor', 'family,family_name', 'family_card,canvasser', 'family_card,first_name']
}
export const fields_type = {
    sponsor: 'object',
    family: 'object',
    canvasser: 'object',
    order: 'object',
    calendar_events: 'array',
    family_events: 'array',
    //family_member: '',
    family_card: 'object'
}
export const fields_keys = {
    sponsor: 'sponsor',
    family: 'family',
    canvasser: 'canvasser',
    order: 'order',
    calendar_events: 'events',
    family_events: 'family_events',
    //family_member: '',
    family_card: 'card_details'
}

export default {
    rpc_calls, actions, fields, fields_type
}