import _ from 'lodash';
import { DateTime } from 'luxon'

const chars = {
    0:0, 1:1, 2:2, 3:3, 4:4, 5:5, 6:6, 7:7, 8:8, 9:9,
    10:'A',
    11:'B',
    12:'C',
    13:'D',
    14:'E',
    15:'F',
    16:'G',
    17:'H',
    18:'I',
    19:'J',
    20:'K',
    21:'L',
    22:'M',
    23:'N',
    24:'O',
    25:'P',
    26:'Q',
    27:'R',
    28:'S',
    29:'T',
    30:'U',
    31:'V',
    32:'W',
    33:'X',
    34:'Y',
    35:'Z'
};

export const generateID = function(prefix = '', length = 10){
    let ID = '';
    for(let i=0;i<length;i++){
        ID += chars[_.random(0, 35)];
    }
    return prefix+ID;
}

export const timestamp = function(){
    return Math.floor(Date.now() /1000);
}

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
export const encodeDate = function(input){
    let _date = null
    let _label = null
    if(input instanceof Date){
        _date = DateTime.fromJSDate(input)
    }
    if(typeof input == 'string' && /[0-9][0-9][0-9][0-9]-[0-9][0-9]?-[0-9][0-9]?/.test(input)){
        _date = DateTime.fromISO(input)
    }
    if(input == null || typeof input == 'undefined'){
        return null
    }
    if(input instanceof Object && typeof input.label != 'undefined'){
        _label = input.label
    }

    return _label || `${months[_date.month - 1]} ${_date.day}`
}
export const decodeDate = function(input, options = {}){
    let { years } = options
    let now = DateTime.now()
    let _years = years || [now.year]
    let { order, set_of_days, months, dow, day, mode, order_target, limit_day } = input
    let _current = null
    let response = []

    for(let year of _years){

        if(typeof day != 'undefined'){
            response.push( now.set({year, month: months[0], day}).toSQLDate() )
        }
        for(let m of months){
            _current = now.set({year,month:m,day:1})
            let diff = _current.weekNumber - 1
            let dow_count = 0
            let dow_first = null
            let before_date = null

            while(_current.month == m){
                if(typeof set_of_days != 'undefined'){
                    if( set_of_days.indexOf(_current.day) != -1 ){
                        response.push(_current.toSQLDate())
                    }
                }
                if(mode == 'loop'){
                    if(dow.indexOf(_current.weekday) != -1){
                        response.push(_current.toSQLDate())
                    }
                }
                if(mode == 'order'){
                    if(order_target == 'week'){
                        if(
                            order.indexOf(_current.weekNumber - diff) != -1 &&
                            dow.indexOf(_current.weekday) != -1
                        ){
                            response.push(_current.toSQLDate())
                        }
                    }
                    if(order_target == 'dow'){
                        if(order.indexOf(-1) != -1){
                            let _index = _current.plus({months:1}).minus({days:1})
                            let _dow = [...dow]
                            while(_index.month == m){
                                if(_dow.indexOf(_index.weekday) != -1){
                                    response.push(_index.toSQLDate())
                                    _dow = _dow.filter(i => i != _index.weekday)
                                }
                                if(_dow.length == 0) break;
                                _index = _index.minus({days:1})
                            }
                        }else{
                            if(dow_first == null) dow_first = _current.weekday
                            if(dow_first == _current.weekday) dow_count++

                            if(
                                order.indexOf(dow_count) != -1 &&
                                dow.indexOf(_current.weekday) != -1
                            ){
                                response.push(_current.toSQLDate())
                            }
                        }
                    }
                }
                if(mode == 'before'){
                    if(
                        _current.day < limit_day &&
                        dow.indexOf(_current.weekday) != -1
                    ){
                        before_date = _current.toSQLDate()
                    }
                    if(_current.day == limit_day){
                        response.push(before_date)
                    }
                }

                _current = _current.plus({days:1})
            }
        }

    }
    return response
}

export const hashParams = function(){
    let hash = location.hash.replace('#', '')
    let params = hash.split('&')
    let response = {}
    for(let param of params){
        let [key, val] = param.split('=')
        response[key] = val
    }
    return response
}

export const dateLimiters = function(addedYears = 0){
    const now = DateTime.now()
    //const next_year = now.plus({years: addedYears})
    const min_date = now.set({month:1, day:1}).toJSDate()
    const max_date = now.set({month:12, day:31}).toJSDate()
    return {
        //now,
        //next_year,
        min_date,
        max_date
    }
}