import { jsPDF } from "jspdf"
import * as arial from  "./arial_narrow"
import * as sourcesans from "./source_sans"
import 'jspdf-autotable'
import JSZip from 'jszip'
import canvg from 'canvg'
import { DateTime } from 'luxon'
import { decodeDate } from './utils'

let doc
let printed = false
let files = []
let order_pager
let last_order
let orders = [] //for Calendar Events pdf
let events_in_page = 0
let header_order = ''

const default_regular_font = sourcesans.regular_font
const default_bold_font = sourcesans.bold_font

export const createDoc = function(params = { orientation: 'p' }){

    if(doc != undefined && printed >= 1)
        files.push( getPdf(`export${files.length}.pdf`) )

    printed = 0

    doc = new jsPDF({
        ...params,
        format: 'letter'
    })

    order_pager = {
        starts: 1,
        footers: []
    }

    for(let _font of [arial, sourcesans]){
        doc.addFileToVFS(`${_font.regular_font}.ttf`, _font.regular_base64)
        doc.addFont(`${_font.regular_font}.ttf`, _font.regular_font, 'normal')
    
        doc.addFileToVFS(`${_font.bold_font}.ttf`, _font.bold_base64)
        doc.addFont(`${_font.bold_font}.ttf`, _font.bold_font, 'normal')
    }
    doc.setFont(default_regular_font)

}

export const getDoc = function(){
    printPager()
    return doc
}

export const putFamilyCard = function(data){

    //if(typeof last_order != 'undefined' && last_order != data.order_id){
    //    printPager()
    //}
    last_order = data.order_id

    if( printed >= 1 ){
        doc.addPage('letter')
    }

    doc.setFontSize(18)
    doc.text("CARE PRINTING AND PUBLISHING INC.", 10, 10)
    doc.setFontSize(6)
    doc.text("www.carepp.com", 10, 13)
    doc.setFontSize(12)
    doc.text("FAMILY CARD", 10, 20)

    doc.autoTable({
        theme: 'plain',
        startY: 22,
        styles: { fontSize: 10, textColor: 0, lineColor: 0, lineWidth: 0.3 },
        margin: {left:10},
        body: [
            [{content: 'Sponsor', styles: {fontStyle: 'bold'}}, {content: data.sponsor || '', colSpan:3 }],
            [{content: 'Canvasser', styles: {fontStyle: 'bold'}}, {content: data.canvasser || '', colSpan:3 }],
            [
                {content: 'Family Name', styles: {fontStyle: 'bold'}}, {content: data.family_name || '', styles: {minCellWidth: 50} },
                {content: 'First Name', styles: {fontStyle: 'bold'}}, {content: data.first_name || '', styles: {minCellWidth: 50} }
            ],
            [
                {content: 'Address', styles: {fontStyle: 'bold'}}, {content: data.address || '', styles: {minCellWidth: 50} },
                {content: 'Delivery', styles: {fontStyle: 'bold'}}, {content: data.delivery || '', styles: {minCellWidth: 50} }
            ],
            [{content: 'City,Prov,Code', styles: {fontStyle: 'bold'}}, {content: data.city_prov_code || '', colSpan:3 }],
            [
                {content: 'Home Tel', styles: {fontStyle: 'bold'}}, {content: data.home_tel || '', styles: {minCellWidth: 50} },
                {content: 'Work Tel', styles: {fontStyle: 'bold'}}, {content: data.work_tel || '', styles: {minCellWidth: 50} }
            ],
            [
                {content: 'Cel Tel', styles: {fontStyle: 'bold'}}, {content: data.cel_tel || '', styles: {minCellWidth: 50} },
                {content: 'Email', styles: {fontStyle: 'bold'}}, {content: data.email || '', styles: {minCellWidth: 50} }
            ]
        ]
    })
    doc.setFontSize(10)
    doc.text(`Calendar qty: ${data.qty} @ $___________ = $ ___________ + ___________ Taxes = $___________ Paid: $___________`, 10, doc.lastAutoTable.finalY + 8)

    let events = data.events.map((item) => { 
        const name = item.name
        const date = item.date?.label
        const type = item.type || item.event_type[0]
        return [name, date, type]
    })

    if(events.length < 20)
        for(let i = events.length;i < 20;i++){
            events[i] = ['','','']
        }

    doc.autoTable({
        theme: 'plain',
        startY: doc.lastAutoTable.finalY + 15,
        styles: { fontSize: 10, textColor: 0, lineColor: 0, lineWidth: 0.3 },
        margin: {left:10},
        head: [[
            {content:'Name', styles: {fontStyle: 'bold'}},
            {content:'Date', styles: {fontStyle: 'bold'}},
            {content:'Type (B,A,M)', styles: {fontStyle: 'bold'}}
        ]],
        body: [
            ...events,
            [
                {content:'',styles:{lineColor: 255, lineWidth: 0}},
                {content:'# of listings', styles: {fontStyle: 'bold'}},
                data.events.length
            ]
        ]
    })

    const footer_position = 263
    const page_diff = doc.internal.getNumberOfPages() - printed
    const start_page = printed + 1
    for(let i = start_page;i<=doc.internal.getNumberOfPages();i++){
        doc.setPage( i )
        order_pager.footers.push(`Order #${data.order_id}`)
        doc.text(`Family #${data.family_id}(${data.family_name}) - Page ${i - printed} of ${page_diff}`, 140, footer_position + 10)
    }
    doc.setPage( doc.internal.getNumberOfPages() )
    doc.text("Customer Signature _______________", 10, footer_position + 5)
    doc.text('No Changes __', 176, footer_position + 5)

    printed += page_diff
}

export const putCalendarEvent = function(data){

    let start_at = 30

    const add_footer = () => {

        order_pager.footers.push(`Meetings and Events Report - Order #${orders.join(', #')}`)

    }
    const add_header = () => {
        let publisher = 'CARE PRINTING AND PUBLISHING INC.'
        let publisher_website = 'www.carepp.com'
        doc.setFontSize(14)
        doc.text(`${publisher} - Meetings and Events report`, 10, 10)
        doc.setFontSize(6)
        doc.text(publisher_website, 10, 13)
        doc.setFontSize(10)
        header_order = 'Order No.'
        doc.text(header_order, 10, 18)
        //doc.text(`Sponsor:   ${data.sponsor}`, 150, 18)
    }

    if(events_in_page >= 4){

        doc.addPage('letter')
        add_header()
        add_footer()
        events_in_page = 0
        orders = []
        last_order = undefined

        const page_diff = doc.internal.getNumberOfPages() - printed
        printed += page_diff
    }

    if(printed == 0 && events_in_page == 0){
        add_header()
        add_footer()
    }

    if(typeof last_order == 'undefined' || last_order != data.order_id){
        orders.push(data.order_id)
        let new_order = ` #${data.order_id}`
        if(typeof last_order != 'undefined') new_order = `, ${new_order}`
        doc.text(new_order, 10 + (header_order.length * 1.8), 18)
        header_order += new_order
    }

    last_order = data.order_id
    events_in_page += 1
    start_at += (events_in_page - 1) * 50
    order_pager.footers[ order_pager.footers.length - 1 ] = `Meetings and Events Report - Order #${orders.join(', #')}`

    let holiday
    if(data.on_holiday === true){
        holiday = 'Yes'
    }else if(data.on_holiday === false){
        holiday = 'No'
    }else{
        holiday = '______ Yes? ______ No?'
    }

    let july
    if(data.on_july === true){
        july = 'July? Yes'
    }else if(data.on_july === false){
        july = 'July? No'
    }else{
        july = 'July? ______ Yes? ______ No?'
    }

    let august
    if(data.on_august === true){
        august = 'August? Yes'
    }else if(data.on_august === false){
        august = 'August? No'
    }else{
        august = 'August? ______ Yes? ______ No?'
    }

    doc.setFontSize(10)
    const left_margin = 15
    doc.text(`Listing: ${data.name}`, left_margin, start_at)
    doc.text(`Meeting Fall On: ${data.date_label}`, left_margin + 10, start_at + 8)
    doc.text(`Will Your Group Meet On Any Public/Stat Holidays: ${holiday}`, left_margin + 10, start_at + 16)
    doc.text(`Will Your Group Meet:`, left_margin + 10, start_at + 24)
    doc.text(`${july}`, left_margin + 60, start_at + 24)
    doc.text(`${august}`, left_margin + 128, start_at + 24)
    doc.text(`Sponsor: ${data.sponsor || '_______________________________'}`, left_margin + 10, start_at + 32)
    doc.text(`Total Listings: ${data.listings || '________'}`, left_margin + 87, start_at + 32)
    doc.text(`@ $___________ = $__________`, left_margin + 128, start_at + 32)
    doc.text(`______________________________________________________________________________________________`, left_margin, start_at + 40)

}

export const putCalendarPage = function( month, year, events, options = {} ){

    let current_font
    switch (options.font) {
        case 'arial':
            current_font = arial
            break;
        case 'sourcesans':
            current_font = sourcesans
            break;
    
        default:
            current_font = sourcesans
            break;
    }
    doc.setFont(options.force_bold_content ? current_font.bold_font : current_font.regular_font)

    if( printed >= 1 ){
        doc.addPage('letter', 'l')
    }

    const anniversary = '♥'
    const birthday = ''
    const memorial = '†'
    const meetings = '°'
    const holiday = ''

    const now = DateTime.now()
    //const year = now.set({year: _year}).year //prev spec: next year as default
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let day = DateTime.fromObject({year, month, day: 1})
    const dow1 = day.weekday
    const dow = [7,1,2,3,4,5,6]
    let days_printed = 0

    const header = 14.5
    const day_header = 4
    const leftm = 4.5
    const topm = header + 4
    const calendar_header = 8
    const footer_space = 4.5
    const wsize = (279 - (leftm * 2))/7
    const hsize = (216 - (calendar_header + topm + footer_space))/6
    const events_limit = options.event_lines_limit ?? 12 //lines
    const top_section = 0.16666666666666666
    const top_lines = Math.floor( events_limit * top_section )
    const events_content_limit = events_limit - top_lines
    const line_size = hsize / events_limit
    doc.rect(leftm + (wsize * 0),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 1),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 2),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 3),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 4),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 5),topm ,wsize, calendar_header)
    doc.rect(leftm + (wsize * 6),topm ,wsize, calendar_header)

    doc.setFont(current_font.bold_font)
    doc.setFontSize(20)
    doc.text(String(months[month-1]).toUpperCase(), leftm, header, {align:'left'})
    doc.setFont(options.force_bold_content ? current_font.bold_font : current_font.regular_font)
    doc.text(String(year), leftm + (wsize * 7), header, {align:'right'})
    doc.setFontSize(7)
    doc.text(
        `${birthday} Birthday`,
        leftm + (wsize * 4.5),
        header,
        {align:'left'}
    )
    doc.text(
        `${memorial} Memorial`,
        leftm + (wsize * 4.5) + 10,
        header,
        {align:'left'}
    )
    doc.text(
        `${anniversary} Anniversaries`,
        leftm + (wsize * 4.5) + 10 + 12,
        header,
        {align:'left'}
    )
    doc.text(
        `${meetings} Meetings`,
        leftm + (wsize * 4.5) + 10 + 12 + 16.5,
        header,
        {align:'left'}
    )
    doc.setFont(current_font.bold_font)
    doc.text(
        `${holiday} Holidays`,
        leftm + (wsize * 4.5) + 10 + 12 + 16.5 + 11,
        header,
        {align:'left'}
    )

    doc.setFont(current_font.bold_font)
    doc.setFontSize(12)
    doc.text('Sun', leftm + (wsize * 0) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Mon', leftm + (wsize * 1) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Tue', leftm + (wsize * 2) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Wed', leftm + (wsize * 3) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Thu', leftm + (wsize * 4) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Fri', leftm + (wsize * 5) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    doc.text('Sat', leftm + (wsize * 6) + wsize/2, topm + (calendar_header/2), {maxWidth:wsize, align:'center', charSpace: 0.6})
    for(let i of [1,2,3,4,5,6]){ //weeks displayed
        for(let d in dow){ //day of weeks

            doc.rect(leftm + (wsize * d),topm + calendar_header + (hsize * (i-1)),wsize, hsize)

            if(dow1 != dow[d] && days_printed == 0) continue;
            if(day.month != month) continue;
            doc.setFont(current_font.bold_font)
            doc.setFontSize(10)
            doc.text(`${day.day}`, leftm + wsize + (wsize * d) - 1, topm + calendar_header + day_header + (hsize * (i-1)), {align:'right'})

            //date.toISOString().substr(0,10)
            doc.setFontSize(options.events_font_size ?? 6.2)

            const long_line = options.events_long_line ?? 17
            let _events = events
                .filter(
                    (event) => decodeDate(event.date, {years: [year]})
                        .find( date => DateTime.fromISO(date).toISODate() == day.toISODate() ) != undefined
                )
                .map((event) => {
                    let _name = event.name
                    _name = _name.replace(/\n/g,'')
                    if(
                        (options.trim_family_events && event.event_type != 'event') ||
                        (options.trim_calendar_events && event.event_type == 'event')
                    ){
                        _name = _name.replace(/\s+/g,' ')
                        _name = _name.replace(/[:;=`~\+\_\-\.,\/\\\*\|\^]+/g,'')
                    }
                    return {...event, name: _name}
                })
                .map((event) => {
                    if(
                        options.cut_very_long_lines &&
                        (
                        (event.event_type == 'Birthday' && event.name.length > long_line) ||
                        (event.event_type == 'Memorial' && event.name.length > (long_line - 2))
                        )
                    ){
                        let _split = event.name.split(' ')
                        if(_split.length == 3){
                            _split[1] = _split[1][0] // reducing to one letter
                            return {...event, name: _split.join(' ')}
                        }
                        return event
                    }
                    if(options.cut_very_long_lines && event.event_type == 'Aniversary' && event.name.length > ((long_line - 2) * 1.5)){
                        let _split = event.name.split(' ')
                        if(_split.length >= 3){
                            _split[_split.length - 1] = _split[_split.length - 1][0]
                            return {...event, name: _split.join(' ')}
                        }
                        return event
                    }
                    return event 
                })
                .sort((a, b) => {
                    if(a.event_type == 'Birthday'){
                        if(b.event_type == 'Memorial' || b.event_type == 'Aniversary' || b.event_type == 'event' || b.event_type == 'holiday') return -1
                    }
                    if(a.event_type == 'Memorial'){
                        if(b.event_type == 'Birthday') return 1
                        if(b.event_type == 'Aniversary' || b.event_type == 'event' || b.event_type == 'holiday') return -1
                    }
                    if(a.event_type == 'Aniversary'){
                        if(b.event_type == 'Memorial' || b.event_type == 'Birthday') return 1
                        if(b.event_type == 'event' || b.event_type == 'holiday') return -1
                    }
                    if(a.event_type == 'event'){
                        if(b.event_type == 'Memorial' || b.event_type == 'Birthday' || b.event_type == 'Aniversary') return 1
                        if(b.event_type == 'holiday') return -1
                    }
                    if(a.event_type == 'holiday'){
                        if(b.event_type == 'Memorial' || b.event_type == 'Birthday' || b.event_type == 'event' || b.event_type == 'Aniversary') return 1
                    }
                    return 0
                })
                .sort((a, b) => {
                    if(a.event_type == b.event_type){
                        if(options.sort_by == 'length'){
                            if(a.name.length < b.name.length) return -1
                            if(a.name.length > b.name.length) return 1
                        }
                        if(options.sort_by == 'alphabetically'){
                            if(a.name.slice(0,2) < b.name.slice(0,2)) return -1
                            if(a.name.slice(0,2) > b.name.slice(0,2)) return 1
                        }
                    }
                    return 0
                })
            _events = _events.filter((event, index) => _events.find((event2,index2) => event2.event_type == event.event_type && event2.name == event.name && index2 > index) === undefined)
            const total_birthdays_lines = Math.ceil(
                _events.reduce((value, item) => {
                    if(item.event_type != 'Birthday') return value
                    if(item.name.length > long_line) return 1 + value
                    return 0.5 + value
                }, 0)
            )
            const total_aniversary_lines = Math.ceil(
                _events.reduce((value, item) => {
                    if(item.event_type != 'Aniversary') return value
                    if(item.name.length + 2 > long_line) return 1 + value
                    return 0.5 + value
                }, 0)
            )
            const total_memorial_lines = Math.ceil(
                _events.reduce((value, item) => {
                    if(item.event_type != 'Memorial') return value
                    if(item.name.length + 2 > long_line) return 1 + value
                    return 0.5 + value
                }, 0)
            )
            const total_holiday_lines = Math.ceil(
                _events.reduce((value, item) => {
                    if(item.event_type != 'holiday') return value
                    return 1 + value
                }, 0)
            )
            const total_event_lines = Math.ceil(
                _events.reduce((value, item) => {
                    if(item.event_type != 'event') return value
                    if(item.name.length + 2 > long_line) return 1 + value
                    return 0.5 + value
                }, 0)
            )
            const total_lines =
                total_birthdays_lines +
                total_aniversary_lines +
                total_memorial_lines +
                total_holiday_lines +
                total_event_lines
            const total_events = _events.reduce((value, item) => {
                if(item.event_type == 'event') return 1 + value
                return value
            }, 0)
            const total_holidays = _events.reduce((value, item) => {
                if(item.event_type == 'holiday') return 1 + value
                return value
            }, 0)
            const get_label = (event) => {
                if(event.event_type == 'holiday') return `${event.name}` //${holiday} 
                if(event.event_type == 'event') return `${meetings} ${event.name}`
                if(event.event_type == 'Aniversary') return `${anniversary} ${event.name}`
                if(event.event_type == 'Birthday') return `${event.name}` //${birthday}
                if(event.event_type == 'Memorial') return `${memorial} ${event.name}`
            }
            const is_long_label = (event) => {
                if(event.event_type == 'Birthday' || event.event_type == 'holiday'){
                    return event.name.length > long_line
                }
                return (event.name.length + 2) > long_line
            }
            const charSpacing = options.char_spacing ?? 0
            let last_event = null
            for(let l = 0;l<events_limit;l++){
                if(_events.length == 0) break;
                if(
                    (
                        total_lines <= events_content_limit &&
                        l + 1 <= top_lines
                    )
                    ||
                    (
                        total_lines > events_content_limit &&
                        total_lines - events_content_limit < top_lines - l
                    )
                ){
                    continue
                }
                if(
                    (
                        options.events_at_bottom &&
                        total_holidays + total_events >= _events.length &&
                        events_limit - (l + total_holidays + total_event_lines) > 0
                    ) ||
                    (total_holidays >= _events.length && events_limit - (l + _events.length) > 0)
                ){
                    continue
                }
                if(
                    total_holidays >= _events.length &&
                    events_limit - (l + _events.length) == 0
                ){
                    doc.setFont(current_font.bold_font)
                    doc.text(
                        get_label( _events.shift() ),
                        leftm + 0.4 + 0.1 + (wsize * d) + (wsize/2),
                        topm + calendar_header + 1.8 + (hsize * (i-1)) + (line_size * l),
                        {maxWidth:wsize/2, align:'left', charSpace: charSpacing, lineHeightFactor: 1.07}
                    )
                    continue
                }
                if( is_long_label(_events[0]) ){
                    doc.setFont( options.force_bold_content ? current_font.bold_font : current_font.regular_font )
                    doc.text(
                        get_label( _events.shift() ),
                        leftm + 0.4 + (wsize * d),
                        topm + calendar_header + 1.8 + (hsize * (i-1)) + (line_size * l),
                        {maxWidth:wsize, align:'left', charSpace: charSpacing, lineHeightFactor: 1.07}
                    )
                    continue
                }
                doc.setFont(options.force_bold_content ? current_font.bold_font : current_font.regular_font)
                last_event = _events.shift()
                doc.text(
                    get_label( last_event ),
                    leftm + 0.4 + (wsize * d),
                    topm + calendar_header + 1.8 + (hsize * (i-1)) + (line_size * l),
                    {maxWidth:wsize, align:'left', charSpace: charSpacing, lineHeightFactor: 1.07}
                )
                for(let x = 0;x<_events.length;x++){
                    if(
                        !is_long_label(_events[x]) &&
                        last_event.event_type == _events[x].event_type &&
                        ( _events[x].event_type != 'holiday' || l + 1 == events_limit )
                    ){
                        if( _events[x].event_type == 'holiday' && l + 1 == events_limit)
                            doc.setFont(current_font.bold_font)
                        doc.text(
                            get_label( _events[x] ),
                            leftm + 0.4 + 0.1 + (wsize * d) + (wsize/2),
                            topm + calendar_header + 1.8 + (hsize * (i-1)) + (line_size * l),
                            {maxWidth:wsize/2, align:'left', charSpace: charSpacing, lineHeightFactor: 1.07}
                        )
                        _events.splice(x,1)
                        break
                    }
                }
            }

            day = day.plus({days: 1})
            days_printed++
        }
    }

    printed++

}

const printPager = function(){
    const footer_position = 263
    for(let i = 0;i<order_pager.footers.length;i++){
        doc.setPage( i + order_pager.starts )
        doc.text(`${order_pager.footers[i]} - Page ${i + 1} of ${order_pager.footers.length}`, 10, footer_position + 10)
    }
    order_pager.starts += order_pager.footers.length
    order_pager.footers = []
}

export const getPdf = function(filename){

    printPager()
    return new File([doc.output()], filename)

}

export const getFiles = function(){
    
    if(doc != undefined && printed >= 1)
        files.push( getPdf(`export${files.length}.pdf`) )

    return files
}

export const getZip = function(){
    const zip = new JSZip()
    return new Promise((resolve, reject) => {
        for(let i = 0;i<files.length;i++){
            zip.file(
                `export${i}.pdf`,
                files[i]
            )    
        }
        zip.generateAsync({type:"blob"})
            .then(function(content) {
                resolve( content )
            })
    })
}