<template>
    <section class="container-fluid">
        <div class="row h-100">
            <div class="col-12 col-md-8 offset-md-2 mt-5">
                <h2>Profile</h2>
                <p>
                    {{profile.email}}<br>
                    {{profile.role}}<br>
                    <span v-for="(key, val) in profile.details">
                    {{key}}: {{val}}<br>
                    </span>
                    {{profile.details}}
                </p>
                <a @click="toSetPass" href="javascript::void">Change password</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        profile: function(){
            return this.$store.getters.getUserInfo
        },
        signedIn: function(){
          return this.$store.getters.isSignedIn
        }
    },
    methods: {
        toSetPass: function(){
            this.$router.push('/setpass')
        }
    },
    mounted(){
        if( this.signedIn == false){
            this.$router.push('/login')
        }
    }
}
</script>

<style></style>