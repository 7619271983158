<template>
    <section class="container-fluid pt-2 h-100 d-flex flex-column">
        <div class="row">
            <div class="col-6 col-md-4 offset-md-2">
                <h3>Manage</h3>
            </div>
            <div v-if="can_manage.length > 1" class="col-6 col-md-4">
                <Dropdown mode="hover">
                    <template v-slot:button>
                        {{profile_type}}
                    </template>
                    <template v-slot:links>
                        <li v-for="type in can_manage">
                            <a
                                @click="profile_type = type"
                                class="dropdown-item"
                                :class="{disabled:(type==profile_type)}">
                            {{type}}
                            </a>
                        </li>
                    </template>
                </Dropdown>
            </div>
        </div>
        <div class="row flex-fill">
            <div class="col-12 col-md-8 offset-md-2">
                <component :is="profile_type"></component>
            </div>
        </div>
    </section>
</template>

<script>
import Dropdown from '../Dropdown'
import Family from './Family'
import Canvasser from './Canvasser'
import Sponsor from './Sponsor'
import Admins from './Admins'

export default {
    data(){
        return {
            profile_type: false,
            can_manage: []
        }
    },
    computed: {
        profile(){
            return this.$store.getters.getUserInfo
        }
    },
    components: {
        Dropdown,Admins,Sponsor,Canvasser,Family
    },
    beforeMount(){
        if(this.profile.role == 'admin'){
            this.profile_type = 'Admins'
            this.can_manage.push('Admins')
            this.can_manage.push('Sponsor')
            this.can_manage.push('Canvasser')
            this.can_manage.push('Family')
        }
        else if(this.profile.role == 'sponsor'){
            this.profile_type = 'Canvasser'
            this.can_manage.push('Canvasser')
            this.can_manage.push('Family')
        }
        else if(this.profile.role == 'canvasser'){
            this.profile_type = 'Family'
            this.can_manage.push('Family')
        }
        else{
            this.$router.push('/')
        }
        
    }
}
</script>

<style scoped>
.dropdown{
    display:block;
    position:relative;
    float:right;
    width:min-content;
}
</style>