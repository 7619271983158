export default {
    computed:{
        profile: function(){
            return this.$store.getters.getUserInfo
        },
        isManager: function(){
            return (this.profile.role == 'admin' || this.profile.role == 'sponsor' || this.profile.role == 'canvasser')
        },
        isOwner: function(){
            return (this.profile.role == 'admin' || this.profile.role == 'sponsor')
        },
        signedIn: function(){
            return this.$store.getters.isSignedIn
        }
    }
}