<template>
    <div class="d-flex flex-column justify-content-center h-100">
        <button class="btn btn-success event-add d-flex flex-column justify-content-center" @click.prevent="buttonClicked()">new event</button>
    </div>
</template>

<script>
import { emmiter } from '../../../main.js'

export default {
    data(){
        return {
        }
    },
    methods: {
        buttonClicked() {
            emmiter.emit('addEvent', null)
        }
    }
}
</script>

<style>
.btn.event-add{
    width:100px;
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>