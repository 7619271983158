<template>
<form ref="form" @submit.prevent id="event-details" :class="{show:mode=='show',edit:mode=='edit',new:mode=='new'}">

    <div id="header-details" class="d-flex flex-row justify-content-between">
        <h3 class="mb-3">Event<span v-if="no_event_properties">(NEW)</span></h3>
        <div>
            <button
                class="btn btn-danger me-2"
                @click.prevent="remove">Remove</button>
            <button
                class="btn btn-danger me-2"
                @click.prevent="cancel">Back</button>
            <button
                class="btn btn-success"
                @click.prevent="save">Save</button>
        </div>
    </div>

    <div id="details">

        <div
            id="sponsor-label"
            v-if="profile.role != 'sponsor'"><h5>Sponsor</h5></div>
        <div
            id="sponsor"
            v-if="profile.role != 'sponsor'">
            <Autocomplete
                name="sponsor"
                v-model="sponsor"
                :suggestions="sponsorsFiltered"
                @complete="($event) => {
                    sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                }"
                field="name"></Autocomplete>
        </div>

        <div id="name-label"><h5>Listing</h5></div><div id="name"><input name="name" v-model="name"></div>
        <div id="date-label"><h5>Meeting Falls On</h5></div>
        <div id="date">

            <v-date-picker class="inline-block h-full" @dayclick="datepick" :max-date="max_date" :min-date="min_date">
                <template v-slot="{ inputValue, togglePopover }">
                    <div class="flex items-center">
                        <button class="btn date-picker" @click="togglePopover()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"></path>
                            </svg>
                        </button>
                        <input v-model="date"/>
                    </div>
                </template>
            </v-date-picker>

        </div>

        <div id="holiday-label">
            <h5>Will Your Group Meet On Any Public/Stat Holidays?</h5>
        </div>
        <div id="holiday">
            <div>
                <label>Yes?</label>
                <input type="radio" value="true" name="holiday" @click="holiday = true" :checked="holiday === true">
            </div>
            <div>
                <label>No?</label>
                <input type="radio" value="false" name="holiday" @click="holiday = false" :checked="holiday === false">
            </div>
        </div>

        <div id="july-label">
            <h5>Will Your Group Meet: July?</h5>
        </div>
        <div id="july">
            <div>
                <label>Yes?</label>
                <input type="radio" values="true" name="july" @click="july = true" :checked="july === true">
            </div>
            <div>
                <label>No?</label>
                <input type="radio" values="false" name="july" @click="july = false" :checked="july === false">
            </div>
        </div>

        <div id="august-label">
            <h5>Will Your Group Meet: August?</h5>
        </div>
        <div id="august">
            <div>
                <label>Yes?</label>
                <input type="radio" values="true" name="august" @click="august = true" :checked="august === true">
            </div>
            <div>
                <label>No?</label>
                <input type="radio" values="false" name="august" @click="august = false" :checked="august === false">
            </div>
        </div>

    </div>

<div id="details-footer" class="d-flex flex-row">
<span>Order #{{event_properties.order_id}}</span>
</div>

</form>
</template>

<script>
import { generateID, encodeDate, dateLimiters } from '../../utils'
import authMixin from '../../mixins/auth'
import { DatePicker } from 'v-calendar'
import { DateTime } from 'luxon'

const {min_date, max_date} = dateLimiters()

export default {
    props: ['event_properties'],
    data(){
        return {
            id: null,
            order_id: null,
            sponsor: null,
            name: null,
            date: null,
            holiday: null,
            july: null,
            august: null,
            public: null,
            sponsorsFiltered: null,
            min_date,
            max_date
        }
    },
    computed: {
        sponsors(){
            return this.$store.getters.getSponsors
        },
        no_event_properties(){
            return Object.keys(this.event_properties).length == 0
        }
    },
    watch: {
        event_properties(_new, _old){
            if(Object.keys(_new).length == 0){
                this.sponsor = null
            }else{
                this.sponsor = { name: _new.sponsor, id: _new.sponsor_id }
            }
            this.id = _new.id
            this.order_id = _new.order_id
            this.name = _new.name
            this.date = _new.date_label
            this.holiday = _new.on_holiday
            this.july = _new.on_july
            this.august = _new.on_august
            this.public = _new.public
        }
    },
    methods: {
        clear(){
            this.id = null
            this.sponsor = null
            this.name = ''
            this.date = ''
            this.holiday = null
            this.july = null
            this.august = null
            this.public = null
            this.order_id = null
        },
        validate(){
            this.$refs.form.querySelectorAll('input').forEach((i)=>{ i.classList.remove('border-danger') })
            let valid = true
            if(this.profile.role == 'admin' && this.sponsor == null){
                this.$refs.form['sponsor'].classList.add('border-danger')
                valid = false
            }
            if(this.$refs.form['name'].value == ''){
                this.$refs.form['name'].classList.add('border-danger')
                valid = false
            }
            return valid
        },
        save(){
            if(this.validate()){
                let sponsor_data = {}
                if(typeof this.sponsor == 'string'){
                    sponsor_data['sponsor'] = this.sponsor
                }else{
                    sponsor_data['sponsor_id'] = this.profile.sponsor_id || this.sponsor.id
                }

                this.$emit('save', {
                    order: {
                        id: this.order_id,
                        ...sponsor_data
                    },
                    events: [{
                        id: this.id,
                        name: this.name,
                        date: this.date,
                        on_july: this.july,
                        on_august: this.august,
                        on_holiday: this.holiday,
                        public: this.public
                    }]
                })
                this.$emit('back')
            }
        },
        remove(){
            if( confirm('Are you sure you want to delete this Meeting?') ){
                this.$emit('remove', this.id)
                this.clear()
                this.$emit('back')
            }
        },
        cancel(){
            this.clear()
            this.$emit('back')
        },
        datepick(e){
            this.date = encodeDate(e.id)
        }
    },
    mixins: [authMixin],
    components: {
        'v-date-picker': DatePicker
    },
    beforeMount(){
        if(this.sponsors.length == 0) this.$store.dispatch('fetchSponsors')
        //if(this.no_event_properties) this.newMode()
    }
}
</script>

<style lang="scss">
#event-details{

    display: grid;
    grid-template-columns: auto;
    overflow:visible;
    grid-template-rows: 40px 1fr 40px;
    grid-template-areas: 
    "header"
    "details"
    "footer";
    &.show{
        grid-template-rows: 40px 1fr 40px;
        grid-template-areas: 
        "header"
        "details"
        "footer";
    }

    #header-details{ grid-area:header; }
    #details{
        grid-area:details;
        display: grid;
        grid-template-columns: 130px 200px 1fr;
        grid-column-gap:30px;
        grid-template-rows: auto;
        grid-template-areas: 
        "sponsor-label sponsor sponsor"
        "name-label name name"
        "date-label date date"
        "holiday-label holiday-label holiday"
        "july-label july-label july"
        "august-label august-label august"
        "public-label public public";
    
        #sponsor-label{ grid-area:sponsor-label;}
        #name-label{ grid-area:name-label;}
        #date-label{ grid-area:date-label;}
        #holiday-label{ grid-area:holiday-label;}
        #july-label{ grid-area:july-label;}
        #august-label{ grid-area:august-label;}
        #public-label{ grid-area:public-label;}
    
        #sponsor{ grid-area:sponsor;}
        #name{ grid-area:name;}
        #date{ grid-area:date;}
        #holiday{ grid-area:holiday;}
        #july{ grid-area:july;}
        #august{ grid-area:august;}
        #public{ grid-area:public;}
    }
    #details-footer{ grid-area:footer; }

    &.show{
        input{
            pointer-events:none;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

}
.btn.date-picker{
    max-width:30px;
    max-height:30px;
    padding:0;
}
</style>