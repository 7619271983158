import {createRouter, createWebHistory} from 'vue-router'
import Login from './components/auth/Login'
import Signup from './components/auth/Signup'
import setPass from './components/auth/setPass'
import Recover from './components/auth/Recover'
import Profile from './components/auth/Profile'
import Meetings from './components/Meetings'
import FamilyCards from './components/FamilyCards'
import Home from './components/Home'
import Calendar from './components/Calendar'
import Migrate from './components/Migrate'
import Manage from './components/manage'
import Orders from './components/Orders'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        strict: true
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/meetings',
        name: 'Meetings',
        component: Meetings,
        children: [
            {
                path: '/meetings/:id',
                name: 'MeetingsDetail',
                component: Meetings,
            },
            {
                path: '/meetings/new',
                name: 'MeetingsNew',
                component: Meetings,
            }
        ]
    },
    {
        path: '/familycards',
        name: 'FamilyCards',
        component: FamilyCards,
        children: [
            {
                path: '/familycards/:id',
                name: 'FamilyCardsDetail',
                component: FamilyCards,
            },
            {
                path: '/familycards/new',
                name: 'FamilyCardsNew',
                component: FamilyCards,
            }
        ]
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar
    },
    {
        path: '/migrate',
        name: 'Migrate',
        component: Migrate
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders
    },
    {
        path: '/manage',
        name: 'Manage',
        component: Manage
    },
    {
        path: '/setpass',
        name: 'SetPass',
        component: setPass
    },
    {
        path: '/recover',
        name: 'Recover',
        component: Recover
    }
];

const router = createRouter({
    history: createWebHistory(
        process.env.VUE_APP_PROJECT_NAME?
            `/${process.env.VUE_APP_PROJECT_NAME}`:
            ''
    ),
    routes
})

export default router
