<template>

<div class="flex flex-column justify-content-center align-content-center text-center">
  <div class="droparea" @dragover.prevent @drop.prevent="drop">
    <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
      class="overflow-hidden position-absolute" @change="onChange" ref="file" accept=".csv" />
  
    <label for="assetsFieldHandle" class="block cursor-pointer">
      <div>
        <slot></slot>
      </div>
    </label>
    <ul class="mt-4" v-if="filelist.length" v-cloak>
      <li class="text-sm p-1" v-for="file in filelist">
        <label id="filename">{{ file.name }}</label>
        <button class="btn btn-danger ms-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">remove</button>
      </li>
    </ul>
  </div>
</div>

</template>

<script>
export default {
  emits: ['dropped'],
  data(){
    return { filelist: [] }
  },
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.$emit('dropped', this.filelist)
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.$emit('removed', this.filelist)
    },
    drop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
    }
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.droparea{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-content:center;

  #filename{
    max-width: 150px;
    overflow: hidden;
    position: relative;
    float: left;
    display: block;
    text-overflow: ellipsis;
  }
  li{
    list-style: none;
  }


}
</style>