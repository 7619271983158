<template>
    <section class="container-fluid">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-6 offset-md-3">
                <h2>Login</h2>
                <LoginForm @submit="signIn" />
                <router-link tag="a" to="/recover" class="p-relative float-end">password lost?</router-link>
                <div class="text-danger" v-if="error"><h5>{{error.title}}</h5>{{error.message}}</div>
            </div>
        </div>
    </section>
</template>

<script>
import LoginForm from './LoginForm'

export default {
    data(){
        return { 
            subscription: null,
            error: false
        }
    },
    computed: {
        signedIn: function(){
            return this.$store.getters.isSignedIn
        },
        logs: function(){
            return this.$store.getters.getUnseenLogs('error', 'auth')
        }
    },
    watch: {
        logs: function(_new, _old){
            if(_new.length > 0){
                this.error = _new[0]
                this.$store.dispatch('logShown', this.error.id)
            }
        }
    },
    components: {
        LoginForm
    },
    methods: {
        signIn: function(email, password){
            this.$store.dispatch('signIn', {email, password})
        }
    },
    beforeMount(){

        const goBack = () => { this.$router.push('/') }
        if(this.$route.params.error_status && this.$route.params.error_description){
            this.error = {
                title: `HTTP${this.$route.params.error_status} ERROR`,
                message: this.$route.params.error_description.replaceAll('+', ' ')
            }
        }
        if(this.signedIn){
            goBack()
        }
        this.subscription = this.$store.subscribe((mutation, state) => {
            if(state.auth.signedIn){
                goBack()
            }
        })
    },
    beforeUnmount(){
        this.subscription()
    }
}
</script>

<style></style>