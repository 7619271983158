import remote from '../remote'

const state = () => {
    return {
        list: []
    }
}

const getters = {
    getAdmins(state){
        return state.list
    }
}

const mutations = {
    setAdminsList(state, list){
        state.list = list
    }
}

const actions = {
    fetchAdmins({rootState, dispatch, commit}){
        return new Promise( async (resolve, reject) => {

            if(/admin/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('list_admin')
                if(error == null){
                    commit('setAdminsList', data)
                    resolve()
                }else reject()
            }else reject()
            
        })
    },
    setAdmin({rootState, dispatch, commit}, new_admin){
        return new Promise( async (resolve, reject) => {
        
            if(/admin/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('set_profile', { options: new_admin })
                if(error == null){
                    resolve()
                }else reject()
            }else reject()
            
        })
    }
}

export default {
    state, getters, mutations, actions
}