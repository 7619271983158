<template>
    <section id="admins-management" class="h-100 d-flex flex-column position-relative">
        <div class="pb-2 d-flex flex-row justify-content-between">
            <h2>Sponsors</h2>
            <button class="btn btn-success" @click.prevent="add">New sponsor</button>
        </div>
        <ag-grid-vue
            class="ag-theme-alpine flex-fill"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :suppressCellSelection="true"
            rowSelection="single"
            @rowClicked="edit">
        </ag-grid-vue>
        <form ref="editor" class="editor" :class="{'d-none':!show_editor}">
            <div
                v-if="editor_mode=='new'"
                class="d-flex flex-row justify-content-between">
                <h4>New sponsor</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <div
                v-if="editor_mode=='edit'"
                class="d-flex flex-row justify-content-between">
                <h4>Edit sponsor</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <fieldset class="flex-fill d-flex flex-column justify-content-center">
                <ul v-if="editor_mode=='edit' && profile.role=='admin'">
                    <li>ID: {{selection.id}}</li>
                    <li>uid: {{selection.uid}}</li>
                </ul>
                <input class="w-100 mb-3" type="name" name="name" placeholder="name" :value="selection.name || ''">
                <input class="w-100 mb-3" type="email" name="email" placeholder="email" :value="selection.email || ''">
                <button class="btn btn-primary submit" @click.prevent="submit">Submit</button>
            </fieldset>
        </form>
    </section>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
export default {
    data(){
        return {
            columnDefs: [],
            rowData: [],
            show_editor: false,
            editor_mode: '',
            selection: {},
            subscription: undefined
        }
    },
    computed: {
        sponsors(){
            return this.$store.getters.getSponsors
        },
        profile(){
            return this.$store.getters.getUserInfo
        }
    },
    methods: {
        add(){
            this.show_editor = true
            this.editor_mode = 'new'
        },
        edit(row){
            this.show_editor = true
            this.editor_mode = 'edit'
            this.selection = row.data
        },
        submit(){
            this.show_editor = false
            let _new = {
                name: this.$refs.editor['name'].value,
                role: 'sponsor'
            }
            let prev = false
            if(this.$refs.editor['email'].value.trim() != ''){
                let email = this.$refs.editor['email'].value.trim()
                if(this.profile.email == email && this.profile.id != this.selection.id){
                    alert("you can not use the email of your current profile in another profile or role")
                    return;
                }
                prev = this.sponsors.find(i => i.email == email )
                if(!prev){
                    _new['email'] = email
                }else{
                    _new['uid'] = prev.uid
                }
            }
            if(this.editor_mode == 'edit')
                _new = {..._new, ...{id: this.selection.id}}

            if(!prev && typeof _new['email'] != 'undefined'){
                this.$store.dispatch('invite', _new)
                .then(() => {
                    this.$store.dispatch('fetchSponsors')
                    .then(() => {
                        this.rowData = this.sponsors
                    })
                })
            }else{
                this.$store.dispatch('setSponsor', _new)
                .then(() => {
                    this.$store.dispatch('fetchSponsors')
                    .then(() => {
                        this.rowData = this.sponsors
                    })
                })
            }
            this.selection = {}
        },
        close(){
            this.show_editor = false
            this.selection = {}
        }
    },
    components: {
        AgGridVue
    },
    created(){
        this.$store.dispatch('fetchSponsors')
    },
    beforeMount() {
        this.columnDefs = [
            { field: 'id' },
            { field: 'uid' },
            { field: 'name' },
            { field: 'email' },
            { field: 'details' }
        ]
        this.rowData = this.sponsors
        this.subscription = this.$store.subscribe((mutations, state) => {
            this.rowData = state.sponsors.list;
        })
    },
    beforeUnmount(){
        this.subscription()
    }
}
</script>

<style lang="scss" scoped>
.editor{
    display:flex;
    position:absolute;
    flex-direction:column;
    justify-content:between;
    align-content:center;
    bottom:15%;
    left:15%;
    width:70%;
    height:70%;
    border:1px solid lightgray;
    background-color:white;
    padding:20px 40px;
    .submit{
        width:min-content;
    }
}
</style>