<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1>Home</h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div v-if="signedIn && !isManager">Please ask for a role assignement to access the Family Cards & Meetings</div>
                <div class="text-center">
                    <img class="home-img" src="../assets/home.jpg" alt="care comunity calendars">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import authMixin from '../mixins/auth'

export default {
    mixins: [authMixin]

}
</script>

<style>
.home-img{
max-width:100%;
max-height:100% !important;
max-height:76vh !important;
}
</style>