<template>
    <Container :tag="tag" ref="dropdown" class="nav-item dropdown" @mouseenter="open" @mouseleave="close">
        <a class="nav-link dropdown-toggle" @click.prevent="open" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <slot name="button"></slot>
        </a>
        <ul class="dropdown-menu" :class="{show:showDropdown}" aria-labelledby="navbarDropdown">
            <slot name="links"></slot>
        </ul>
    </Container>
</template>

<script>
import { h } from 'vue'

const Container = {
    props: ['tag'],
    render(){
        return h(this.tag, this.$slots.default())
    }
}

export default {
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        mode: {
            type: String,
            default: 'click'
        }
    },
    data(){
        return {
            showDropdown: false
        }
    },
    methods: {
        open(e){
            if(this.mode == 'click' && e.type == 'click'){
                this.showDropdown = !this.showDropdown
            }else if(this.mode == 'hover' && e.type == 'mouseenter'){
                if(this.showDropdown == false) this.showDropdown = true
            }
        },
        close(e){
            if(this.mode == 'hover' && e.type == 'mouseleave'){
                if(this.showDropdown == true) this.showDropdown = false
            }
        }
    },
    mounted(){
        this.$emmiter.on('globalClick', (e) => {
            if(
                this.mode == 'click' &&
                this.showDropdown == true &&
                this.$refs.dropdown != e.target &&
                !this.$refs.dropdown.$el.contains(e.target)
            ){
                this.showDropdown = false
            }
        })
    },
    components: {
        Container
    }
}
</script>

<style lang="scss">
.dropdown-menu.show{
right:0;
}
.dropdown{
    &, & *{
        max-height:none !important;
    }
}
</style>