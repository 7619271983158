<template>
    <section class="container-fluid">
        <div class="row h-100 py-3">
            <div class="col-12">
                <div id="migrator">
                    <button v-if="!solivingIssue" class="btn btn-success start" :class="{disabled:(!migrationType || (migrationType && migrating))}" @click.prevent="migrate">
                        Migrate
                    </button>
                    <div v-else class="btn-group" role="group" aria-label="solve issue">
                        <button class="btn btn-success" @click.prevent="continue">Continue</button>
                        <button class="btn btn-warning" @click.prevent="skip">Skip</button>
                    </div>
                    <ag-grid-vue
                        class="ag-theme-alpine table"
                        :columnDefs="columnDefs"
                        :suppressCellSelection="true"
                        :suppressRowSelection="true"
                        :rowClassRules="rowStyling"
                        :gridOptions="gridOptions"
                        @cellClicked="cellEditing"
                        @cellValueChanged="cellEdited"
                        ref="table">
                    </ag-grid-vue>
                    <div v-if="migrating" class="picker d-flex flex-row justify-content-center align-items-center text-center">
                        Migrating...<br>
                        {{issue.message || issue}}
                    </div>
                    <FilePicker v-else class="picker" @dropped="processFile" @removed="clearFile">
                        Drop file with data to migrate (.csv)
                    </FilePicker>
                    <form v-if="ready && migrationType && !migrating" class="groups d-flex flex-row justify-content-around">
                        <div class="d-flex flex-column">
                            <label class="w-100">Group</label>
                            <select name="grouped_field" v-model="groupedField">
                                <option value="null">select field</option>
                                <option v-for="field in rpc_calls[migrationType].filter(i => fields_type[i] == 'array')" :value="field">{{field.replaceAll('_', ' ')}}</option>
                            </select>
                        </div>
                        <div class="d-flex flex-column">
                            <label class="w-100">By</label>
                            <select multiple v-model="groupedBy">
                                <option value="null">select field</option>
                                <optgroup v-for="field in rpc_calls[migrationType].filter(i => fields_type[i] != 'array')" :label="field.replaceAll('_', ' ')">
                                    <option v-for="(type, arg) in fields[field]" :value="`${field},${arg}`">{{arg}}</option>
                                </optgroup>
                            </select>
                        </div>
                    </form>
                    <div v-else class="groups d-flex flex-row align-items-center justify-content-center">
                        <span v-if="!migrating">select migration type and add CSV file</span>
                    </div>
                    <div class="matcher" :class="{disabled: migrating}">
                        <div class="matcher-container" v-if="ready && migrationType">
                            <div class="db-fields">
                                <div class="db-field" v-for="argument in rpc_calls[migrationType]">
                                    <div :data-label="argument.replaceAll('_',' ')">
                                        <label class="field" v-for="(type, index) in fields[argument]">{{index}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="csv-fields">
                                <form :id="`${argument}Form`" :ref="argument" v-for="argument in rpc_calls[migrationType]" class="csv-field">
                                    <div class="field" v-for="(type, index) in fields[argument]">
                                        <Multiselect v-model="matches[`${argument}__${index}`]" v-if="type == 'multiselect'" class="form-select" :options="[...columnDefs]" optionLabel="headerName" optionValue="field">
                                        </Multiselect>
                                        <select v-else v-model="matches[`${argument}__${index}`]" class="form-select" :name="index">
                                            <option v-for="column in columnDefs" :value="column.field">
                                                {{column.headerName}}
                                            </option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div v-if="!migrationType" class="d-flex flex-column justify-content-around h-100">
                            <button v-for="(val, key) in rpc_calls" class="btn btn-info text-capitalize mx-4 mb-3" @click="migrationType=key">{{key.replaceAll('_',' ')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3'
import FilePicker from '../ui/FilePicker'
import {
    rpc_calls,
    actions,
    fields,
    defaults,
    fields_type,
    fields_keys,
    default_group_field,
    default_group_by
} from './calls'

export default {
    data(){
        return {
            ready: false,
            migrating: false,
            migrationType: false,
            migrationIndex: 0,
            solivingIssue: false,
            issue: '',
            rowData: [],
            groupedField: 'null', 
            groupedBy: 'null', 
            columnDefs: [],
            rowStyling: {
                'migration-error': params => params.node.data.error == true,
                'migration-success': params => params.node.data.migrated == true
            },
            gridOptions: {},
            csv: '',
            lines: [],
            fields,
            fields_type,
            rpc_calls,
            matches: {}
        }
    },
    computed: {
        profile(){
            return this.$store.getters.getUserInfo
        }
    },
    watch: {
        'migrationType': function(_new, _old){
            if(_new != false && this.ready == true){
                this.selectDefaults()
            }
        },
        'ready': function(_new, _old){
            if(_new == true && this.migrationType != false){
                this.selectDefaults()
            }
        }
    },
    components: {
        FilePicker, AgGridVue
    },
    methods:{
        processFile(files){

            for(let file of files){

                const reader = new FileReader()
                reader.addEventListener('load', (e) => {
                    if(/^data:text\/csv;base64/.test(e.target.result)){
                        this.csv = atob(e.target.result.replaceAll('data:text/csv;base64,', ''))
                    }if(/^data:application\/vnd.ms-word;base64/.test(e.target.result)){
                        this.csv = atob(e.target.result.replaceAll('data:application/vnd.ms-word;base64,', ''))
                    }if(/^data:application\/vnd.ms-excel;base64/.test(e.target.result)){
                        this.csv = atob(e.target.result.replaceAll('data:application/vnd.ms-excel;base64,', ''))
                    }if(/^data:.+;base64/.test(e.target.result)){
                        this.csv = atob(e.target.result.replaceAll(/^data:.+;base64,/g, ''))
                    }else{
                        this.csv = e.target.result
                    }
                    this.csv = this.csv.replaceAll('\r', '')
                    this.lines = this.csv.split('\n')
                    this.columnDefs = this.lines[0].split(',').map((el, i) => {
                        return {
                            field: `${i}`,
                            headerName: el.replaceAll(/[\'\"]/g, ''),
                            editable: true
                        }
                    })
                    let data = [];
                    for(let j = 1;j<this.lines.length;j++){

                        let obj = {}
                        let empty = true
                        let split = this.lines[j]
                            .replaceAll(/(?<=[\"\'].+),(?=.+[\"\'])/g, '.')
                            .split(',')
                        for(let k=0;k<split.length;k++){
                            let _col = split[k].trim().replaceAll(/[\'\"]/g, '')
                            if(_col != '' && _col != '-') empty = false
                            obj[`${k}`] = _col
                        }
                        if(!empty){
                            obj['migrated'] = false
                            obj['error'] = false
                            data.push(obj)
                        }

                    }
                    this.rowData = data
                    this.gridOptions.api.setRowData(this.rowData)
                })
                reader.readAsDataURL(file)

            }
            this.ready = true
        },
        selectDefaults(){
            for(let argument of rpc_calls[this.migrationType]){
                for(let field in defaults[argument]){

                    if( fields[argument][field] == 'multiselect' ){
                        let val
                        for(let possible_default of defaults[argument][field]){
                            val = this.columnDefs.find((i) => i.headerName.trim().toLowerCase()==possible_default)
                            if(typeof val != 'undefined'){
                                if(typeof this.matches[`${argument}__${field}`] == 'undefined'){
                                    this.matches[`${argument}__${field}`] = []
                                }
                                this.matches[`${argument}__${field}`] = [
                                    ...this.matches[`${argument}__${field}`],
                                    val.field
                                ]
                            }
                        }

                    }else{

                        let val
                        for(let possible_default of defaults[argument][field]){
                            val = this.columnDefs.find((i) => i.headerName.trim().toLowerCase()==possible_default.trim().toLowerCase())
                            if(typeof val != 'undefined'){
                                this.matches[`${argument}__${field}`] = val.field
                                break
                            }
                        }

                    }

                }
                if(argument == default_group_field[this.migrationType]){
                    this.groupedField = argument
                    this.groupedBy = []
                    for(let _group_by of default_group_by[argument]){
                        this.groupedBy = [
                            ...this.groupedBy,
                            _group_by
                        ]
                    }
                }
            }
        },
        clearFile(){
            if(!this.migrating){
                this.ready = false
                this.rowData = []
                this.columnDefs = []
                this.lines = []
                this.csv = ''
                this.migrationType = false
                this.solivingIssue = false
            }
        },
        abortMigration(){
            this.migrating = false
            this.lines = []
            this.csv = ''
            this.migrationType = false
            this.solivingIssue = false
            this.migrationIndex = 0
        },
        stopMigration(error){
            this.rowData[this.migrationIndex].error = true
            this.gridOptions.api.applyTransaction({ update: [ this.rowData[this.migrationIndex] ] })

            let i = 1
            if( typeof this.rowData[this.migrationIndex + i] != 'undefined' ){
                while(this.rowData[this.migrationIndex + i].migrated == true){
                    this.rowData[this.migrationIndex + i].migrated = false
                    this.rowData[this.migrationIndex + i].error = true
                    this.gridOptions.api.applyTransaction({ update: [ this.rowData[this.migrationIndex + i] ] })
                    i++
                }
            }

            this.solivingIssue = true
            this.issue = error
            this.gridOptions.api.ensureNodeVisible(this.rowData[this.migrationIndex])
        },
        cellEditing(params){
            if(this.solivingIssue && this.rowData[params.node.rowIndex].error){
                this.gridOptions.api.setFocusedCell(params.node.rowIndex, params.colDef.field)
                this.gridOptions.api.startEditingCell({
                    rowIndex: params.node.rowIndex,
                    colKey: params.colDef.field
                })
            }
        },
        cellEdited(params){
            this.rowData[params.node.rowIndex][params.colDef.field] = params.data[params.colDef.field]
            this.gridOptions.api.stopEditing()
        },
        continue(){
            delete this.rowData[this.migrationIndex].error
            delete this.rowData[this.migrationIndex].migrated
            this.gridOptions.api.applyTransaction({ update: [ this.rowData[this.migrationIndex] ] })

            let i = 1
            while(
                this.rowData[this.migrationIndex + i].migrated == true ||
                this.rowData[this.migrationIndex + i].error == true
            ){
                delete this.rowData[this.migrationIndex + i].error
                delete this.rowData[this.migrationIndex + i].migrated
                this.gridOptions.api.applyTransaction({ update: [ this.rowData[this.migrationIndex + i] ] })
                i++
            }
            this.solivingIssue = false
            this.processRow(this.migrationIndex)
        },
        skip(){

            while(
                typeof this.rowData[this.migrationIndex] != 'undefined' &&
                (this.rowData[this.migrationIndex].migrated == true ||
                this.rowData[this.migrationIndex].error == true)
            ){
                this.migrationIndex++
            }

            this.solivingIssue = false
            if(typeof this.rowData[this.migrationIndex] != 'undefined'){
                this.processRow(this.migrationIndex)
            }else{
                this.migrating = false
                this.migrationIndex = 0
            }

        },
        migrate(prev_result = undefined){
            if(this.ready){
                if(!this.migrating){
                    this.migrating = true
                    this.processRow(this.migrationIndex)
                }else{
                    if(
                        prev_result.error != null && prev_result.error != false
                    ){
                        this.stopMigration(prev_result.error)
                        return
                    }
                    this.rowData[this.migrationIndex].error = false
                    this.rowData[this.migrationIndex].migrated = true
                    this.gridOptions.api.applyTransaction({ update: [ this.rowData[this.migrationIndex] ] })
                    this.gridOptions.api.ensureNodeVisible(this.rowData[this.migrationIndex])
                    if(this.rowData.length > (this.migrationIndex + 1)){
                        this.migrationIndex++
                        this.processRow(this.migrationIndex)
                    }else{
                        this.migrating = false
                        this.solivingIssue = false
                        this.migrationIndex = 0
                    }
                }
            }
        },
        processCurrentRow(){
            this.processRow(this.migrationIndex)
        },
        processRow(row_id){
        
            if(this.rowData[row_id].migrated == true){
                this.migrate(true)
                return
            }

            let args = {}
            const processArgs = (i, group) => {
                for(let index of this.rpc_calls[this.migrationType]){

                    const setVal = (obj) => {
                        for(let field in this.fields[index]){
                            let _val
                            if( this.matches[`${index}__${field}`] instanceof Array ){
                                _val = ''
                                for(let match of this.matches[`${index}__${field}`] ){
                                    _val += this.rowData[i][ match ] + ' '
                                }
                                _val = _val.trim()
                                obj[field] = _val
                            }else{
                                _val = this.rowData[i][ this.matches[`${index}__${field}`] ]
                                if(field == 'active'){
                                    if( _val == 'No' || _val == 'no' || _val == 'False' || _val == 'false' ){
                                        obj[field] = false
                                    }else{
                                        obj[field] = true
                                    }
                                }else if( this.fields[index][field] == 'boolean' ){
                                    if( _val == 'Yes' || _val == 'yes' || _val == 'True' || _val == 'true' ){
                                        obj[field] = true
                                    }else{
                                        obj[field] = false
                                    }
                                }else if(typeof _val != 'undefined' && _val != null){
                                    if( _val.trim ) _val = _val.trim()
                                    obj[field] = _val
                                }
                            }
                        }
                        return obj
                    }
                    const setArray = () => {
                        let obj = {}
                        obj = setVal(obj)
                        if(typeof group != undefined && index == group)
                            args[fields_keys[index]] = [ ...args[group], obj ]
                        else
                            args[fields_keys[index]] = [ obj ]
                    }
                    const setObj = () => {
                        args[fields_keys[index]] = setVal(args[fields_keys[index]])
                    }

                    if(this.fields_type[index] == 'array'){
                        if(typeof args[fields_keys[index]] == 'undefined') args[fields_keys[index]] = []
                        setArray()
                    }else{
                        if(typeof args[fields_keys[index]] == 'undefined') args[fields_keys[index]] = {}
                        setObj()
                    }

                }
            }

            if(this.groupedField == 'null' && this.groupedBy == 'null'){

                processArgs(row_id)

            }else{

                processArgs(row_id)
                this.rowData[row_id].error = false
                this.rowData[row_id].migrated = true

                let same = true
                let mod = 1
                while(same){
                    if( typeof this.rowData[row_id + mod] == 'undefined' ){
                        same = false
                        break
                    }

                    for(let _by of this.groupedBy){
                        let [arg, field] = _by.split(',')
                        if( this.rowData[row_id][ this.$refs[arg][field].value ] != 
                            this.rowData[row_id + mod][ this.$refs[arg][field].value ] )
                            same = false
                    }
                    if(same){
                        processArgs(row_id + mod, this.groupedField)
                        this.rowData[row_id + mod].error = false
                        this.rowData[row_id + mod].migrated = true
                        mod++
                        if(this.rowData.length <= (row_id + mod)){
                            break
                        }
                    }else{
                        break
                    }
                }

            }
            
            const req = this.$store.dispatch(actions[this.migrationType], { options: args })
            req.then(this.migrate.bind(this))
            req.catch((error) => {
                this.stopMigration(error)
            })
            //this.migrate(false)

        }
    }
}
</script>

<style lang="scss">
#migrator{
    width:100%;
    height:100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 60px 20% 15% 1fr;
    column-gap: 10px;
    grid-template-areas: 
    "start table"
    "picker table"
    "groups table"
    "matcher table";
    .groups{
        grid-area: groups;
    }
    .start{
        grid-area: start;
        font-size:25px;
        font-weight:bold;
    }
    .picker{
        grid-area: picker;
        background-color:lightgray;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-content:center;
        input{
            width: 1px;
            position: absolute;
            overflow: hidden;
            opacity: 0;
        }
    }
    .matcher{
        grid-area: matcher;
        overflow-y: scroll;
        overflow-x:hidden;
        &.disabled{ &, & *{opacity:0.7;pointer-events:none;} }
        & > *:first-child{

            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-areas: 
            "label form";

            .db-fields{
                grid-area: label;
                .db-field{
                    &:nth-child(odd){
                    background-color:lightcyan;
                    }
                    & > *{
                        position:relative;
                        &::before{
                            content:attr(data-label);
                            position:absolute;
                            top:0;
                            left:0;
                            $size: 20px;
                            font-size:$size;
                            opacity: 0.5;
                            transform: rotate(-90deg) translate(-100px, -80px);
                            height: $size;
                            width: 200px;
                            text-align: right;
                            line-height: $size;
                            text-transform: capitalize;
                        }
                    }
                    position:relative;
                    *{ max-height:none; }
                }
            }
            .csv-fields{
                grid-area: form;
                position:relative;
                *{ max-height:none; }
            }
            .field{
                width:100%;
                height:60px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .table{
        grid-area: table;
        &, & *{
            max-height:none;
        }
        .migration-success{ background-color:lightgreen; }
        .migration-error{ background-color:lightcoral; }
    }
}
</style>