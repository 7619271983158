<template>
    <section class="container-fluid">
        <div class="row h-100">
            <div class="h-100 d-flex flex-column" :class="{'col-4':!showing,'d-none':showing}">
                <button class="mb-2 btn btn-success" @click="newEvent">New</button>
                <div class="btn-group mb-2" role="group" aria-label="Print to PDF">
                    <button
                        class="btn"
                        :class="{'btn-info': !loading, 'btn-secondary': loading}"
                        @click="export">
                        Print
                        </button>
                    <button
                        class="btn"
                        :class="{'btn-info': !loading, 'btn-secondary': loading}"
                        @click="export_all">
                        Print All
                        </button>
                </div>
                <button
                    class="mb-2 btn"
                    :class="{'btn-info': !loading, 'btn-secondary': loading}"
                    @click="export_CSV">
                    export CSV
                    </button>
                <div>
                    <Autocomplete
                        v-if="profile.role != 'sponsor'"
                        name="sponsor"
                        v-model="sponsor"
                        class="w-100 mb-2"
                        forceSelection
                        :suggestions="sponsorsFiltered"
                        @complete="($event) => {
                            sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                        }"
                        field="name"
                        placeholder="Sponsor"
                        @item-select="_watcher"
                        @item-unselect="_watcher"
                        @clear="_watcher"></Autocomplete>
                    <Autocomplete
                        name="order"
                        v-model="order"
                        class="w-100 mb-2"
                        forceSelection
                        :suggestions="ordersFiltered"
                        @complete="($event) => {
                            ordersFiltered = orders
                                .filter( i => (sponsor != null)?(i.sponsor_id == sponsor.id):true )
                                .filter( i => RegExp(`.*${$event.query}.*`).test(i['id'])  )
                        }"
                        field="id"
                        placeholder="Orders"
                        @item-select="_watcher"
                        @item-unselect="_watcher"
                        @clear="_watcher"></Autocomplete>
                    <MonthSelect ref="month" @change="_watcher"></MonthSelect>
                </div>
            </div>
            <div class="h-100 d-flex flex-column" :class="{'col-8':!showing,'d-none':showing}">
                <ag-grid-vue
                    class="table ag-theme-alpine flex-fill mb-1"
                    :gridOptions="gridOptions"
                    :suppressCellSelection="true"
                    rowSelection="single"
                    @rowDoubleClicked="showEvent"
                    rowModelType="infinite"
                    :cacheOverflowSize="0">
                </ag-grid-vue>
                <span class="mb-2">double click to check meeting</span>
            </div>
            <div :class="{'col-12':showing,'d-none':!showing}">
                <Details class="py-2" @back="showing=false" @save="save" @remove="remove" :event_properties="{...event_properties}"></Details>
            </div>
        </div>
    </section>
</template>

<script>
import { getPdf, createDoc, putCalendarEvent } from "../../pdf"
import { AgGridVue } from 'ag-grid-vue3'
import Details from './Details'
import { encodeDate } from '../../utils'
import { DateTime } from 'luxon'
import { sortByOrder } from './sorting'
import MonthSelect from '../ui/MonthSelect'
import { saveAs } from 'file-saver'
import authMixin from '../../mixins/auth'
import labels from '../../labels'
import boolRenderer from './gridComponents/boolRenderer'

export default {
    data(){
        return {
            subscription: false,
            currentEvent: 0,
            event_properties: {},
            columnDefs: [],
            exports: [],
            
            sponsor: null,
            order: null,

            sponsorsFiltered: null,
            ordersFiltered: null,
            gridOptions: {},

            filterOptions: null,
            gridLastRow: null,
            isSet: false,
            showing: false,
            loading: false
        }
    },
    computed: {
        orders(){
            return this.$store.getters.getOrders
        },
        sponsors(){
            return this.$store.getters.getSponsors
        },
        calendar_events(){
            return this.$store.getters.getCalendarEvents
                .filter( i => (this.sponsor != null)?(i.sponsor == this.sponsor.name):true )
                .filter( i => (this.order != null)?(i.order_id == this.order.id):true )
                .filter( i => (this.$refs.month != null && this.$refs.month.value != null)?i.date.find(d => DateTime.fromSQL(d).month == this.$refs.month.value):true)
                .sort(sortByOrder)
        },
        pager(){
            const pager = this.$store.getters.getEventsPager('meetings')
            return (typeof pager == 'undefined')?pager:pager['current']
        }
    },
    methods:{
        _watcher(){
            let options = {}
            if( this.order != null ){ options['order_id'] = this.order.id }
            if( this.sponsor != null ){ options['sponsor_id'] = this.profile.sponsor_id || this.sponsor.id }
            if( this.$refs.month.value != null ){ options['month'] = this.$refs.month.value }
            this.filterOptions = options
            this.dataSource()
        },
        setTable(){
            
            let pre = {}

            if(this.calendar_events.length == 0) return false

            for(let i in this.calendar_events[0]){

                if(i == 'date') continue;

                let coldef = {
                    field: i,
                    headerName: labels[i]
                }
                if(/.*id$/.test(i)) coldef['width'] = 100

                if(/^on_.+/.test(i)) coldef['cellRendererFramework'] = 'boolRenderer'
                
                if(i == 'name' || i == 'date_label' || i == 'sponsor')
                    pre[i] = coldef
                else
                    this.columnDefs.push(coldef)
            }
            this.columnDefs = [
                pre['sponsor'],
                pre['name'],
                pre['date_label'],
                ...this.columnDefs
            ]
            this.gridOptions.api.setColumnDefs(this.columnDefs)
            return true

        },
        dataSource(){

            this.gridOptions.api.setDatasource({
                getRows: ({startRow, endRow, successCallback, failCallback}) => {
                    this.$store
                        .dispatch('fetchCalendarEvents', this.filterOptions)
                        .then(() => {
                            if(!this.isSet){
                                this.isSet = this.setTable()
                                if(!this.isSet) return;
                            }
                            //this.gridOptions.api.setRowCount(this.pager.total)
                            
                            let slice
                            if(startRow == 0 && this.pager.items >= this.pager.total){
                                slice = this.calendar_events
                                this.gridLastRow = this.calendar_events.length
                            }else{
                                slice = this.calendar_events
                                    .slice(startRow, endRow)
                                
                                if (this.calendar_events.length <= endRow) {
                                    this.gridLastRow = this.calendar_events.length
                                    if(this.gridLastRow < this.pager.total){
                                        this.gridLastRow += 1
                                    }
                                }
                            }

                            successCallback(slice, this.gridLastRow)
                        })
                }
            })

        },
        showEvent(row){
            this.event_properties = row.data
            this.currentEvent = row.rowIndex
            this.showing = true
        },
        newEvent(){
            this.event_properties = {}
            this.currentEvent = 0
            this.showing = true
        },
        export(){
            createDoc()
            const row = this.gridOptions.api.getSelectedNodes()[0]
            this.event_properties = row.data
            this.currentEvent = row.rowIndex
            let event = this.event_properties
            putCalendarEvent(event)

            saveAs(
                getPdf(`${event.sponsor}_${event.name}.pdf`)
            )
        },
        export_all(){
            this.loading = true
            this.$store
                .dispatch('fetchCalendarEvents', {all: true, ...this.filterOptions} )
                .then(() => {

                    createDoc()
                    for(let current = 0;current<this.calendar_events.length;current++){
                        let event = this.calendar_events[current]
        
                        putCalendarEvent(event)
                    }
        
                    saveAs(
                        getPdf('export.pdf')
                    )

                    this.dataSource()
                    this.loading = false

                })
        },
        export_CSV(){
            this.loading = true
            this.$store
                .dispatch('fetchCalendarEvents', {all: true, ...this.filterOptions} )
                .then(() => {

                    let _head = [   "id",
                                    "name",
                                    "active",
                                    "public",
                                    "details",
                                    "on_july",
                                    "sponsor",
                                    "listings",
                                    "order_id",
                                    "on_august",
                                    "date_label",
                                    "event_type",
                                    "on_holiday",
                                    "sponsor_id"    ]

                    let _export = [
                        _head.join(',') + "\n" 
                    ]

                    for(let current = 0;current<this.calendar_events.length;current++){
                        let event = this.calendar_events[current]
                        _export.push(
                            [   `"${event["id"] ?? ''}"`,
                                `"${event["name"] ?? ''}"`,
                                `"${event["active"] ?? ''}"`,
                                `"${event["public"] ?? ''}"`,
                                `"${event["details"] ?? ''}"`,
                                `"${event["on_july"] ?? ''}"`,
                                `"${event["sponsor"] ?? ''}"`,
                                `"${event["listings"] ?? ''}"`,
                                `"${event["order_id"] ?? ''}"`,
                                `"${event["on_august"] ?? ''}"`,
                                `"${event["date_label"] ?? ''}"`,
                                `"${event["event_type"] ?? ''}"`,
                                `"${event["on_holiday"] ?? ''}"`,
                                `"${event["sponsor_id"] ?? ''}"`    ].join(',') + "\n"
                        )
                    }
                    var blob = new Blob(_export, {type: "text/csv;charset=utf-8"});
        
                    saveAs(blob, 'export.csv')

                    this.dataSource()
                    this.loading = false
                    
                })
        },
        save(event){
            this.$store.dispatch('setCalendarEvents', {
                options: event
            }).then(({done, data, error}) => {
                this.dataSource()
            })
        },
        remove(id){
            this.$store
                .dispatch('removeCalendarEvent', id)
                .then(({error}) => {
                    if(error == null)
                        this.dataSource()
                })
        }
    },
    components: {
        Details, AgGridVue, MonthSelect, boolRenderer
    },
    mixins: [authMixin],
    beforeMount(){

        if(!this.isOwner){
            this.$router.push('/')
        }

        if(this.orders.length == 0){ this.$store.dispatch('fetchOrders') }
        if(this.sponsors.length == 0){ this.$store.dispatch('fetchSponsors') }
    },
    mounted(){

        this.dataSource()
        
        this.subscription = this.$store.subscribe((mutations, state) => {
            if(mutations.type == 'setUserInfo'){
                this.$store.dispatch('fetchOrders')
                this.$store.dispatch('fetchSponsors')
            }
        })
    }
}
</script>

<style>
.p-autocomplete-input{
width:100%;
}
</style>