<template>
    <div class="date-editor" ref="container">
        <DatePicker ref="picker" class="date-picker" v-model="event_date" :max-date="max_date" :min-date="min_date" :is-required="true" @input="finish" @dayclick="finish"></DatePicker>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import { DatePicker } from 'v-calendar'
import { encodeDate, dateLimiters } from '../../../utils'
import { DateTime } from 'luxon'

const {min_date, max_date} = dateLimiters()

export default {
    data() {
        return {
            event_date: DateTime.now().plus({years: 1}).toJSDate(),
            min_date,
            max_date
        };
    },
    methods: {
        getValue() {
            return DateTime.fromJSDate(this.event_date).toISODate()
        },
        isPopup() {
            return true
        },
        finish() {
            this.params.api.stopEditing()
        }
        //isCancelBeforeStart() {
        //    return false
        //},
        //isCancelAfterEnd() {
        //    return true
        //}
    },
    components: {DatePicker},
    mounted() {
        if(this.params.value){
            this.event_date = DateTime.fromSQL(this.params.value).toJSDate()
            this.$refs.picker.move(this.event_date)
        }
        nextTick(() => {
            this.$refs.container.focus()
        })
    }
}
</script>

<style>
.date-picker{
    width:100%;
    height:100%;
    display: inline-block;
}
.date-editor{
    width:250px;
    height:280px;
    display:inline-block;
    outline:none;
}
</style>

