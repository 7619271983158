export const sortByCanvasser = (a, b) => {
    let orderA = a.order_id
    let orderB = b.order_id
    let sponsorA = a.sponsor.toUpperCase()
    let sponsorB = b.sponsor.toUpperCase()
    let canvasserA = a.canvasser.toUpperCase()
    let canvasserB = b.canvasser.toUpperCase()
    let familyA = a.family_name.toUpperCase()
    let familyB = b.family_name.toUpperCase()
    const sorting = (a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }
    let sort = 0
    sort += sorting(orderA, orderB)
    if(sort == 0)
        sort += sorting(sponsorA, sponsorB)
    if(sort == 0)
        sort += sorting(canvasserA, canvasserB)
    if(sort == 0)
        sort += sorting(familyA, familyB)
    return sort
}