export function _pager(data){
    let pager
    let key
    if(data instanceof Array && typeof data[0].total != 'undefined' && typeof data[0].offset != 'undefined'){
        pager = data.shift()
        pager['items'] = data.length
        key = _pagerkey(pager)
    }else return {
        data,
        then: function(){
            return {
                data
            }
        }
    }

    return {
        then: function(callback){
            let prev
            if(typeof callback == 'function'){
                prev = callback(key)
                if(typeof prev != 'undefined')
                    pager['items'] += prev['items']
            }
            return {
                pager: { [key]: pager },
                data,
                key
            }
        },
        pager: { [key]: pager },
        data,
        key
    }
}

// this function generates keys for the pagers
// by making base64 string based on the obj (search options) content
export function _pagerkey(pager){
    
    if(typeof pager == 'undefined' || pager == null) return 'default'
    let obj = {...pager}
    delete obj['offset']
    delete obj['total']
    delete obj['limit']
    delete obj['items']
    delete obj['all']
    let empty = true
    for(let i in obj){
        if(obj[i] == null || typeof obj[i] == 'undefined'){
            delete obj[i]
            continue
        }
        empty = false
        obj[i] = String(obj[i])
    }

    if(empty) return 'default'
    
    obj = Object.entries(obj)
        .sort( (a, b) => (a[0] < b[0])?-1:1 )
        .map( r => r.join(':'))
        .join(',')
    
    return obj
}