import remote from '../remote'

const state = () => {
    return {
        list: []
    }
}

const getters = {
    getCanvassers(state){
        return state.list
    }
}

const mutations = {
    setCanvasserList(state, list){
        state.list = list
    }
}

const actions = {
    fetchCanvassers({rootState, dispatch, commit}){
        return new Promise( async (resolve, reject) => {

            if(/(admin|canvasser|sponsor)/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('list_canvasser')

                if(error == null){
                    commit('setCanvasserList', data)
                    resolve(data)
                }else reject(error)

            }else reject('unauthorized')

        })
    },
    setCanvasser({rootState, dispatch, commit}, new_canvaser){
        return new Promise( async (resolve, reject) => {

            let {
                id,
                uid,
                sponsor_id,
                name,
                address,
                details,
                billing_details,
                billing_address
            } = new_canvaser

            if(/(admin|sponsor)/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('set_profile', { 'options': {
                    id,
                    uid,
                    sponsor_id,
                    name,
                    address,
                    details,
                    billing_details,
                    billing_address,
                    profile_type: 'canvasser'
                    }
                })

                if(error == null){
                    //dispatch('fetchCanvasser')
                    resolve()
                }else reject()

            }else reject()

        })
    }
}

export default {
    state, getters, mutations, actions
}