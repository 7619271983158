<template>
<form ref="form" @submit.prevent id="card-details">

    <div id="header-details" class="d-flex flex-row justify-content-between">
        <h3 class="mb-3">FAMILY CARD<span v-if="no_card_properties">(NEW)</span></h3>
        <div>
            <button
                class="btn btn-danger me-2"
                @click.prevent="remove">Remove</button>
            <button
                class="btn btn-danger me-2"
                @click.prevent="cancel">Back</button>
            <button
                class="btn btn-success"
                @click.prevent="save">Save</button>
        </div>
    </div>

    <div id="details">

        <div
            id="sponsor-label"
            v-if="profile.role == 'admin'"><h5>Sponsor</h5></div>
        <div
            id="sponsor"
            v-if="profile.role == 'admin'">
            <Autocomplete
                name="sponsor"
                v-model="sponsor"
                :suggestions="sponsorsFiltered"
                @complete="($event) => {
                    sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                }"
                field="name">
                <template #item="slotProps">
                    <div><strong>{{slotProps.item.name}}</strong> <span class="text-secondary">#{{slotProps.item.id}}</span></div>
                </template>
            </Autocomplete>
        </div>

        <div
            id="canvasser-label"
            v-if="profile.role != 'canvasser'"><h5>Canvasser</h5></div>
        <div
            id="canvasser"
            v-if="profile.role != 'canvasser'">
            <Autocomplete
                name="canvasser"
                v-model="canvasser"
                :suggestions="canvassersFiltered"
                @complete="($event) => {
                    canvassersFiltered = canvassers
                        .filter( i => (sponsor != null)?(i.sponsor == sponsor.name):true )
                        .filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                }"
                field="name">
                <template #item="slotProps">
                    <div><strong>{{slotProps.item.name}}</strong> <span class="text-secondary">#{{slotProps.item.id}}</span></div>
                </template>
            </Autocomplete>
        </div>

        <div id="family-label"><h5>Family Name</h5></div>
        <div id="family">
            <Autocomplete
                name="family"
                v-model="family"
                :suggestions="familiesFiltered"
                @complete="autocompleteFilter($event.query.toLowerCase(), 'families', 'familiesFiltered')"
                field="family_name">
                <template #item="slotProps">
                    <div><strong>{{slotProps.item.family_name}}</strong> <span class="text-secondary">#{{slotProps.item.id}}</span></div>
                </template>
            </Autocomplete>
        </div>
        <div id="name-label"><h5>First Name</h5></div><div id="name"><input name="first_name" v-model="first_name"></div>

        <div id="address-label"><h5>Physical Address</h5></div><div id="address"><input name="address" v-model="address"></div>
        <div id="delivery-label"><h5>Box #</h5></div><div id="delivery"><input name="delivery" v-model="delivery"></div>

        <div id="cityprovcode-label"><h5>City,Prov,Code</h5></div><div id="cityprovcode"><input name="city_prov_code" v-model="city_prov_code"></div>

        <div id="hometel-label"><h5>Home Tel</h5></div><div id="hometel"><input name="home_tel" v-model="home_tel"></div>
        <div id="worktel-label"><h5>Work Tel</h5></div><div id="worktel"><input name="work_tel" v-model="work_tel"></div>
        <div id="celtel-label"><h5>Cel Tel</h5></div><div id="celtel"><input name="cel_tel" v-model="cel_tel"></div>
        <div id="email-label"><h5>Email</h5></div><div id="email"><input name="email" v-model="email"></div>
    </div>

<div id="calendar-details" class="d-flex flex-row align-items-center">
<h5 class="me-3">Calendar Qty</h5><input name="qty" v-model="qty" type="number" min="0">
</div>

<div class="table d-flex flex-column mb-0">
    <ag-grid-vue
        class="ag-theme-alpine flex-fill mb-1"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :suppressCellSelection="false"
        rowSelection="single"
        :getRowNodeId="i => i._id"
        @cellValueChanged="eventsEdited">
    </ag-grid-vue>
    <span class="mb-0">double click cell to edit it</span>
</div>

<div id="details-footer" class="d-flex flex-row justify-content-between">
<span>Order #{{card_properties.order_id}}</span>
<span>Family #{{card_properties.family_id}}</span>
</div>

</form>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3'
import { DateTime } from 'luxon'
import { generateID, encodeDate } from '../../utils'
import dateEditor from './gridComponents/dateEditor'
import dateRenderer from './gridComponents/dateRenderer'
import eventTypeEditor from './gridComponents/eventTypeEditor'
import eventTypeRenderer from './gridComponents/eventTypeRenderer'
import eventHeader from './gridComponents/eventHeader'
import eventRemove from './gridComponents/eventRemove'
import authMixin from '../../mixins/auth'

export default {
    props: ['card_properties'],
    data(){
        return {
            gridOptions: {},
            columnDefs: [
                {
                    field:'name',
                    headerName: 'Name',
                    editable: true
                },
                {
                    field:'date',
                    headerName: 'Date',
                    cellRendererFramework: 'dateRenderer',
                    cellEditorFramework: 'dateEditor',
                    editable: true,
                    onCellDoubleClicked: (params) => {
                        this.gridOptions.api.startEditingCell({
                            rowIndex: params.rowIndex,
                            colKey: params.colDef.field
                        })
                    }
                },
                {
                    field:'type',
                    headerName: 'Type (B,A,M)',
                    cellRendererFramework: 'eventTypeRenderer',
                    cellEditorFramework: 'eventTypeEditor',
                    editable: true
                },
                {
                    field:'remove',
                    headerName: '',
                    headerComponentFramework: 'eventHeader',
                    cellRendererFramework: 'eventRemove',
                    editable: false
                }
            ],
            family_card_id: null,
            sponsor: null,
            canvasser: null,
            family: null,
            first_name: null,
            address: null,
            delivery: null,
            city_prov_code: null,
            home_tel: null,
            work_tel: null,
            cel_tel: null,
            email: null,
            qty: 0,
            events: [],

            sponsorsFiltered: null,
            canvassersFiltered: null,
            familiesFiltered: null
        }
    },
    computed: {
        sponsors(){
            return this.$store.getters.getSponsors
        },
        canvassers(){
            return this.$store.getters.getCanvassers
        },
        families(){
            return this.$store.getters.getFamilies
        },
        no_card_properties(){
            return Object.keys(this.card_properties).length == 0
        }
    },
    watch: {
        card_properties: _watcher
    },
    methods: {
        clear(){
            this.family_card_id = null
            this.sponsor = null
            this.canvasser = null
            this.family = null
            this.first_name = ''
            this.address = ''
            this.delivery = ''
            this.city_prov_code = ''
            this.home_tel = ''
            this.work_tel = ''
            this.cel_tel = ''
            this.email = ''
            this.qty = ''
            this.events = []
            if(this.gridOptions.api){
                this.gridOptions.api.setRowData(this.events)
            }
        },
        validate(){
            this.$refs.form.querySelectorAll('input').forEach((i)=>{ i.classList.remove('border-danger') })
            let valid = true
            if(this.profile.role == 'admin' && this.sponsor == null){
                this.$refs.form['sponsor'].classList.add('border-danger')
                valid = false
            }
            if(this.profile.role != 'canvasser' && this.canvasser == null){
                this.$refs.form['canvasser'].classList.add('border-danger')
                valid = false
            }
            if(this.family == null){
                this.$refs.form['family'].classList.add('border-danger')
                valid = false
            }
            if(this.$refs.form['first_name'].value == ''){
                this.$refs.form['first_name'].classList.add('border-danger')
                valid = false
            }
            return valid
        },
        save(){
            if(this.validate()){
                this.gridOptions.api.stopEditing()

                let canvasser_data = {}
                if(
                    typeof this.canvasser == 'string' &&
                    this.profile.role != 'canvasser'
                ){
                    canvasser_data['canvasser'] = this.canvasser
                }else{
                    canvasser_data['canvasser_id'] = this.profile.canvasser_id || this.canvasser.id
                }

                let sponsor_data = {}
                if(
                    typeof this.sponsor == 'string' &&
                    this.profile.role == 'admin'
                ){
                    sponsor_data['sponsor'] = this.sponsor
                }else{
                    sponsor_data['sponsor_id'] = this.profile.sponsor_id || this.sponsor.id
                }

                let family_data = {}
                if( typeof this.family == 'string' ){
                    family_data = {
                        family_name: this.family
                    }
                }else{
                    family_data = {
                        family_id: this.family.id,
                        family_name: this.family.family_name,
                    }
                }

                this.$emit('save', {
                    order: {
                        id: this.card_properties.order_id,
                        ...sponsor_data
                    },
                    family: {
                        ...family_data
                    },
                    card_details: {
                        id: this.family_card_id,
                        first_name: this.first_name,
                        address: this.address,
                        delivery: this.delivery,
                        city_prov_code: this.city_prov_code,
                        home_tel: this.home_tel,
                        work_tel: this.work_tel,
                        cel_tel: this.cel_tel,
                        email: this.email,
                        qty: Number(this.qty),
                        ...canvasser_data
                    },
                    family_events: this.events
                })
                this.$emit('back')
            }
        },
        cancel(){
            this.clear()
            this.$emit('back')
        },
        remove(){
            if( confirm('Are you sure you want to delete this Family Card?') ){
                this.$emit('remove', this.family_card_id)
                this.clear()
                this.$emit('back')
            }
        },
        eventsEdited(params){
            let index
            let row = this.events.find((el, i) => {
                if(el._id == params.data._id) index = i
                return el._id == params.data._id
            })
            if(typeof row == 'undefined') return;
            row.name = params.data.name
            row.type = params.data.type
            row.date = params.data.date
            this.events[index] = row
        },
        addEvent(){
            let now = DateTime.now()
            let new_event = {
                _id: generateID('', 4),
                name: '',
                date: `${now.toLocaleString({month:'short'})} ${now.day}`,
                type: 'B',
                new: true
            }
            let result = this.gridOptions.api.applyTransaction({ add: [ new_event ] })
            this.events.push(new_event)
        },
        removeEvent(_id){
            if(this.events.length == 0) return;
            let index
            let row = this.events.find((el, i) => {
                index = i
                return el._id == _id
            })
            let result = this.gridOptions.api.applyTransaction({ remove: [row] })
            this.events[index].delete = true
        },
        autocompleteFilter(search, source, filtered){
            let filterBySponsor = i => {
                    return (this.sponsor != null && typeof i.sponsor_id != 'undefined')?
                        i.sponsor_id == this.sponsor.id:true
                }

            this[filtered] = this[source]
                .filter(filterBySponsor)
                .filter( i => {
                    let searchExp = `.*${search}.*`
                    let sourceName = i['name'] || i['family_name']
                    return RegExp(searchExp).test(sourceName.toLowerCase())
                })

            if(source == 'canvassers' || source == 'families'){

                let call
                if(source == 'canvassers'){
                    call = 'fetchCanvassers'
                }
                if(source == 'families'){
                    call = 'fetchFamilies'
                }
                this.$store.dispatch(call, search)
                .then((data) => {

                    if(!(data instanceof Array)) data = [data]

                    this[filtered] = [
                        ...data.filter(filterBySponsor),
                        ...this[filtered].filter(i => !data.find(d => d.id == i.id))
                        ]
                    
                })

            }
        },
        watcher: _watcher
    },
    components: {
        AgGridVue,
        dateEditor,
        dateRenderer,
        eventTypeEditor,
        eventTypeRenderer,
        eventRemove,
        eventHeader
    },
    mixins: [authMixin],
    beforeMount(){
        if(this.sponsors.length == 0) this.$store.dispatch('fetchSponsors')
        if(this.canvassers.length == 0) this.$store.dispatch('fetchCanvassers')
        if(this.families.length == 0) this.$store.dispatch('fetchFamilies')
    },
    mounted(){
        this.gridOptions.api.setRowData(this.events)
        this.$emmiter.on('removeEvent', (_id) => {
            this.removeEvent(_id)
        })
        this.$emmiter.on('addEvent', (data) => {
            if(data == null) this.addEvent()
        })
    }
}

function _watcher(_new, _old){
    if(Object.keys(_new).length == 0){
        this.sponsor = null
        this.canvasser = null
        this.family = null
    }else{
        this.sponsor = { name: _new.sponsor, id: _new.sponsor_id }
        this.canvasser = { name: _new.canvasser, id: _new.canvasser_id }
        this.family = { family_name: _new.family_name, id: _new.family_id }
    }
    this.family_card_id = _new.family_card_id
    this.first_name = _new.first_name
    this.address = _new.address
    this.delivery = _new.delivery
    this.city_prov_code = _new.city_prov_code
    this.home_tel = _new.home_tel
    this.work_tel = _new.work_tel
    this.cel_tel = _new.cel_tel
    this.email = _new.email
    this.qty = _new.qty
    if(typeof _new.events != 'undefined'){
        this.events = _new.events.map(i => {
            return {
                ...i,
                _id: generateID('', 4),
                name: i.name || i.family_member,
                date: i.date,
                type: i.type || i.event_type[0]
            }
        })
        .sort((a, b) => {
            if(a.type == 'B'){
                if(b.type == 'M' || b.type == 'A') return -1
            }
            if(a.type == 'M'){
                if(b.type == 'B') return 1
                if(b.type == 'A') return -1
            }
            if(a.type == 'A'){
                if(b.type == 'M' || b.type == 'B') return 1
            }
            if(a.type == b.type){
                if(a.name < b.name) return -1
                if(a.name > b.name) return 1
            }
            return 0
        })
        this.gridOptions.api.setRowData(this.events)
    }else{
        this.events = []
        this.gridOptions.api.setRowData([])
    }
}
</script>

<style lang="scss">
#card-details{

    display: grid;
    grid-template-columns: auto;
    overflow:visible;
    grid-template-columns: 30% 1fr;
    grid-template-rows: 50px 1fr 40px 40px;
    grid-column-gap:20px;
    grid-template-areas: 
    "header header"
    "details events"
    "qty events"
    "footer footer";

    #header-details{ grid-area:header; }
    #details{
        grid-area:details;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap:30px;
        grid-template-rows: auto;
        grid-template-areas: 
        "sponsor-label sponsor"
        "canvasser-label canvasser"
        "family-label family"
        "name-label name"
        "address-label address"
        "delivery-label delivery"
        "cityprovcode-label cityprovcode"
        "hometel-label hometel"
        "worktel-label worktel"
        "celtel-label celtel"
        "email-label email";
    
        #sponsor-label{ grid-area:sponsor-label;}
        #canvasser-label{ grid-area:canvasser-label;}
        #family-label{ grid-area:family-label;}
        #name-label{ grid-area:name-label;}
        #address-label{ grid-area:address-label;}
        #delivery-label{ grid-area:delivery-label;}
        #cityprovcode-label{ grid-area:cityprovcode-label;}
        #worktel-label{ grid-area:worktel-label;}
        #hometel-label{ grid-area:hometel-label;}
        #celtel-label{ grid-area:celtel-label;}
        #email-label{ grid-area:email-label;}
    
        #sponsor{ grid-area:sponsor;}
        #canvasser{ grid-area:canvasser;padding:5px 0;}
        #family{ grid-area:family;}
        #name{ grid-area:name;}
        #address{ grid-area:address;}
        #delivery{ grid-area:delivery;}
        #cityprovcode{ grid-area:cityprovcode;}
        #worktel{ grid-area:worktel;}
        #hometel{ grid-area:hometel;}
        #celtel{ grid-area:celtel;}
        #email{ grid-area:email;}
    }
    #calendar-details{ grid-area:qty; }
    .table{ grid-area:events; }
    #details-footer{ grid-area:footer; }

    //#events-edit{
    //    grid-area:events-edit;
    //    display:flex;
    //    flex-direction:row;
    //    padding-bottom:5px;
    //    & > *:first-child{flex-grow:5;}
    //    & > *{flex-grow:1;}
    //    svg{
    //        width:15px;
    //        height:15px;
    //    }
    //}

    //&.show{
    //    input{
    //        pointer-events:none;
    //        border: 1px solid rgba(0, 0, 0, 0.2);
    //    }
    //}

}
</style>