<template>
    <section class="container-fluid" v-if="isManager">
        <div class="row h-100">
            <div class="h-100 d-flex flex-column" :class="{'col-4':!showing,'d-none':showing}">
                <button class="mb-2 btn btn-success" @click="newCard">New</button>
                <div class="mb-2 btn-group" role="group" aria-label="Print to PDF">
                    <button
                        class="btn"
                        :class="{'btn-info': !loading, 'btn-secondary': loading}"
                        @click="export">
                        Print
                        </button>
                    <button
                        class="btn"
                        :class="{'btn-info': !loading, 'btn-secondary': loading}"
                        @click="export_all">
                        Print All
                        </button>
                </div>
                <button
                    class="mb-2 btn"
                    :class="{'btn-info': !loading, 'btn-secondary': loading}"
                    @click="export_CSV">
                    export CSV
                    </button>
                <div>
                    <Autocomplete
                        v-if="profile.role == 'admin'"
                        name="sponsor"
                        v-model="sponsor"
                        class="mb-2 w-100"
                        forceSelection
                        :suggestions="sponsorsFiltered"
                        @complete="($event) => {
                            sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                        }"
                        field="name"
                        placeholder="Sponsor"
                        @item-select="_watcher"
                        @item-unselect="_watcher"
                        @clear="_watcher"></Autocomplete>
                    <Autocomplete
                        v-if="profile.role == 'admin' || profile.role == 'sponsor'"
                        name="canvasser"
                        v-model="canvasser"
                        class="mb-2 w-100"
                        forceSelection
                        :suggestions="canvassersFiltered"
                        @complete="($event) => {
                            canvassersFiltered = canvassers
                                .filter( i => (sponsor != null)?(i.sponsor == sponsor.name):true )
                                .filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                        }"
                        field="name"
                        placeholder="Canvasser"
                        @item-select="_watcher"
                        @item-unselect="_watcher"
                        @clear="_watcher"></Autocomplete>
                    <Autocomplete
                        name="order"
                        v-model="order"
                        class="mb-2 w-100"
                        forceSelection
                        :suggestions="ordersFiltered"
                        @complete="($event) => {
                            ordersFiltered = orders
                                .filter( i => (sponsor != null)?(i.sponsor_id == sponsor.id):true )
                                .filter( i => RegExp(`.*${$event.query}.*`).test(i['id'])  )
                        }"
                        field="id"
                        placeholder="Orders"
                        @item-select="_watcher"
                        @item-unselect="_watcher"
                        @clear="_watcher"></Autocomplete>
                    <input type="text" class="form-control mb-2" v-model="event_name" placeholder="Family member name" @change="_watcher">
                    <input type="text" class="form-control mb-2" v-model="family_name" placeholder="Family name" @change="_watcher">
                    <input type="text" class="form-control mb-2" v-model="family" placeholder="Family #" @change="_watcher">
                </div>
            </div>
            <div class="h-100 d-flex flex-column" :class="{'col-8':!showing,'d-none':showing}">
                <ag-grid-vue
                    class="table ag-theme-alpine flex-fill mb-1"
                    :gridOptions="gridOptions"
                    @gridReady="dataSource"
                    :suppressCellSelection="true"
                    rowSelection="single"
                    @rowDoubleClicked="showCard"
                    rowModelType="infinite"
                    :cacheOverflowSize="0">
                </ag-grid-vue>
                <span class="mb-2">double click to check family card</span>
            </div>
            <div class="h-100" :class="{'col-12':showing,'d-none':!showing}">
                
                <Details class="py-2 h-100" @back="hideCard" @save="save" @remove="remove" :card_properties="{...card_properties}"></Details>

            </div>
        </div>
    </section>
</template>

<script>
import { putFamilyCard, getPdf, createDoc } from "../../pdf"
import { AgGridVue } from 'ag-grid-vue3'
import Details from './Details'
import { sortByCanvasser } from './sorting'
//import { encodeDate } from '../../utils'
import { DateTime } from 'luxon'
import MonthSelect from '../ui/MonthSelect'
import { saveAs } from 'file-saver'
import authMixin from '../../mixins/auth'
import labels from '../../labels'

export default {
    data(){
        return {
            subscription: false,
            currentCard: 0,
            card_properties: {},
            columnDefs: [],
            exports: [],
            
            sponsor: null,
            canvasser: null,
            order: null,
            event_name: null,
            family_name: null,
            family: null,

            sponsorsFiltered: null,
            canvassersFiltered: null,
            ordersFiltered: null,
            familiesFiltered: null,
            gridOptions: {},

            filterOptions: null,
            gridLastRow: null,
            isSet: false,
            showing: false,
            loading: false
        }
    },
    computed: {
        orders(){
            return this.$store.getters.getOrders
        },
        sponsors(){
            return this.$store.getters.getSponsors
        },
        canvassers(){
            return this.$store.getters.getCanvassers
        },
        families(){
            return this.$store.getters.getFamilies
        },
        family_cards(){
            return this.$store.getters.getFamilyCards
                .filter( i => {
                    if(this.sponsor == null) return true
                    return i.sponsor_id == this.sponsor.id
                })
                .filter( i => {
                    if(this.canvasser == null) return true
                    return i.canvasser_id == this.canvasser.id 
                })
                .filter( i => {
                    if(this.order == null) return true
                    return i.order_id == this.order.id 
                })
                .filter( i => {
                    if( this.$refs.month == null ||
                        this.$refs.month.value == null ||
                        this.$refs.month.value == '' ||
                        typeof this.$refs.month.value == 'undefined') return true
                    return i.events
                        .find(ev => DateTime.fromISO(ev.date).month == this.$refs.month.value)
                })
                .filter( i => {
                    if( this.event_name == null ||
                        this.event_name == '' ||
                        typeof this.event_name == 'undefined' ) return true
                    return i.events
                        .find( ev => RegExp(`.*${this.event_name.toLowerCase().replaceAll(' ', '\s')}.*`).test(ev.name.toLowerCase()) )
                })
                .filter( i => {
                    if(this.family_name == null || this.family_name == '') return true
                    return RegExp(`.*${this.family_name.toLowerCase().replaceAll(' ', '\s')}.*`)
                        .test(i.family_name.toLowerCase())
                })
                .filter( i => {
                    if(this.family == null || this.family == '') return true
                    return i.family_id == this.family
                })
                .sort(sortByCanvasser)
        },
        family_events(){
            return this.$store.getters.getFamilyEvents
        },
        pager(){
            const pager = this.$store.getters.getEventsPager('family_cards')
            return (typeof pager == 'undefined')?pager:pager['current']
        }
    },
    methods:{
        _watcher(){
            let options = {}
            if( this.canvasser != null ){ options['canvasser_id'] = this.profile.canvasser_id || this.canvasser.id }
            if( this.sponsor != null ){ options['sponsor_id'] = this.profile.sponsor_id || this.sponsor.id }
            if( this.order != null ){ options['order_id'] = this.order.id }
            if( this.$refs.month != null && this.$refs.month.value != null ){ options['month'] = this.$refs.month.value }
            if( this.event_name != null && this.event_name != "" ){ options['event_name'] = this.event_name }
            if( this.family_name != null &&  this.family_name != "" ){ options['family_name'] = this.family_name }
            if( this.family != null && this.family != ''){ options['family_id'] = this.family }
            this.filterOptions = options
            this.dataSource()
        },
        setTable(){
            let pre = {}
            if(this.family_cards.length == 0) return false
            for(let i in this.family_cards[0]){
                if(i == 'events') continue;

                let coldef = {
                    field: i,
                    headerName: labels[i]
                }
                if(/.*id$/.test(i)) coldef['width'] = 100
                if(i == 'family_card_id') coldef['width'] = 120
                if(i == 'qty') coldef['width'] = 70

                if(i == 'family_name' || i == 'first_name' || i == 'canvasser' || i == 'sponsor')
                    pre[i] = coldef
                else
                    this.columnDefs.push(coldef)
            }
            this.columnDefs = [
                pre['sponsor'],
                pre['family_name'],
                pre['canvasser'],
                pre['first_name'],
                ...this.columnDefs
            ]
            this.gridOptions.api.setColumnDefs(this.columnDefs)
            return true

        },
        dataSource(){

            this.gridOptions.api.setDatasource({
                getRows: ({startRow, endRow, successCallback, failCallback}) => {
                    this.$store
                        .dispatch('fetchFamilyCard', this.filterOptions)
                        .then(() => {
                            if(!this.isSet){
                                this.isSet = this.setTable()
                                if(!this.isSet) return;
                            }
                            //this.gridOptions.api.setRowCount(this.pager.total)
                            
                            let slice
                            if(startRow == 0 && this.pager.items >= this.pager.total){
                                slice = this.family_cards
                                this.gridLastRow = this.family_cards.length
                            }else{
                                slice = this.family_cards
                                    .slice(startRow, endRow)
                                
                                if (this.family_cards.length <= endRow) {
                                    this.gridLastRow = this.family_cards.length
                                    if(this.gridLastRow < this.pager.total){
                                        this.gridLastRow += 1
                                    }
                                }
                            }

                            successCallback(slice, this.gridLastRow)
                        })
                }
            })

        },
        showCard(row){
            this.card_properties = row.data
            this.currentCard = row.rowIndex
            this.showing = true
            this.$router.push(`/familycards/${this.card_properties.family_card_id}`)
        },
        hideCard(){
            this.showing = false
            this.$router.push(`/familycards/`)
        },
        newCard(){
            this.card_properties = {}
            this.currentCard = 0
            this.showing = true
            this.$router.push(`/familycards/new`)
        },
        export(){
            createDoc()
            const row = this.gridOptions.api.getSelectedNodes()[0]
            this.card_properties = row.data
            this.currentCard = row.rowIndex
            let card = this.card_properties
            putFamilyCard(card)

            saveAs(
                getPdf(`${card.sponsor}_${card.family_name}_${card.first_name}.pdf`)
            )
        },
        export_all(){
            this.loading = true
            this.$store
                .dispatch('fetchFamilyCard', {all: true, ...this.filterOptions} )
                .then(() => {

                    createDoc()
                    for(let current = 0;current<this.family_cards.length;current++){
                        let card = this.family_cards[current]
        
                        putFamilyCard(card)
                    }
        
                    saveAs(
                        getPdf('export.pdf')
                    )

                    this.dataSource()
                    this.loading = false

                })
        },
        export_CSV(){
            this.loading = true
            this.$store
                .dispatch('fetchFamilyCard', {all: true, ...this.filterOptions} )
                .then(() => {

                    let _head = [   "qty",
                                    "email",
                                    "price",
                                    "taxes",
                                    "event_id",
                                    "date",
                                    "name",
                                    "type",
                                    "address",
                                    "sponsor",
                                    "delivery",
                                    "home_tel",
                                    "order_id",
                                    "work_tel",
                                    "canvasser",
                                    "family_id",
                                    "first_name",
                                    "sponsor_id",
                                    "family_name",
                                    "canvasser_id",
                                    "address_city",
                                    "family_card_id"    ]

                    let _export = [
                        _head.join(',') + "\n" 
                    ]

                    for(let current = 0;current<this.family_cards.length;current++){
                        let card = this.family_cards[current]
                        for(let event of card['events']){
                            _export.push(
                                [   `"${card["qty"] ?? ''}"`,
                                    `"${card["email"] ?? ''}"`,
                                    `"${card["price"] ?? ''}"`,
                                    `"${card["taxes"] ?? ''}"`,
                                    `"${event["id"] ?? ''}"`,
                                    `"${event["date_label"] ?? ''}"`,
                                    `"${event["name"] ?? ''}"`,
                                    `"${event["event_type"] ?? ''}"`,
                                    `"${card["address"] ?? ''}"`,
                                    `"${card["sponsor"] ?? ''}"`,
                                    `"${card["delivery"] ?? ''}"`,
                                    `"${card["home_tel"] ?? ''}"`,
                                    `"${card["order_id"] ?? ''}"`,
                                    `"${card["work_tel"] ?? ''}"`,
                                    `"${card["canvasser"] ?? ''}"`,
                                    `"${card["family_id"] ?? ''}"`,
                                    `"${card["first_name"] ?? ''}"`,
                                    `"${card["sponsor_id"] ?? ''}"`,
                                    `"${card["family_name"] ?? ''}"`,
                                    `"${card["canvasser_id"] ?? ''}"`,
                                    `"${card["city_prov_code"] ?? ''}"`,
                                    `"${card["family_card_id"] ?? ''}"`  ].join(',') + "\n"
                            )
                        }
                    }
                    var blob = new Blob(_export, {type: "text/csv;charset=utf-8"});
        
                    saveAs(blob, 'export.csv')

                    this.dataSource()
                    this.loading = false
                    
                })
        },
        save(card){
            for(let event of card.family_events){
                if(event.delete){
                    this.$store.dispatch('removeFamilyEvent', {id: event.id, card_id: card.card_details.id})
                }
            }
            let _events = card.family_events
                .filter(i => typeof i.delete == 'undefined')
                .filter(i => i.name != '')

            this.$store.dispatch('setFamilyCard', {
                options: {
                    ...card,
                    family_events: _events
                }
            }).then(() => {

                this.$store.dispatch('fetchOrders')
                this.$store.dispatch('fetchSponsors')
                this.$store.dispatch('fetchCanvassers')
                this.$store.dispatch('fetchFamilies')

                this.dataSource()
                
            })
        },
        remove(id){
            this.$store
                .dispatch('removeFamilyCard', id)
                .then(({error}) => {
                    if(error == null)
                        this.dataSource()
                })
        },
        initialize(){
            if(this.signedIn){

                if(this.orders.length == 0){ this.$store.dispatch('fetchOrders') }
                if(this.sponsors.length == 0){ this.$store.dispatch('fetchSponsors') }
                if(this.canvassers.length == 0){ this.$store.dispatch('fetchCanvassers') }
                if(this.families.length == 0){ this.$store.dispatch('fetchFamilies') }

                if(this.$route.params.id){
                    this.$store.dispatch('familyCardDetails', this.$route.params.id)
                    .then((data) => {
                        if(data == null){
                            this.$router.push(`/familycards/`)
                            return;
                        }
                        this.card_properties = data
                        this.currentCard = 0
                        this.showing = true
                    })
                    .catch(() => {
                        this.$router.push(`/familycards/`)
                    })
                }

                if(/familycards\/new\/?/.test(this.$route.path)){
                    this.card_properties = {}
                    this.currentCard = 0
                    this.showing = true
                }
                
            }
        }
    },
    components: {
        Details, AgGridVue, MonthSelect
    },
    mixins: [authMixin],
    beforeMount(){
        this.initialize()
    },
    mounted(){

        this.subscription = this.$store.subscribe((mutations, state) => {
            if(mutations.type == 'setUserInfo'){
                this.initialize()
            }
        })

    }
}
</script>

<style>
.p-autocomplete-input{
width:100%;
}
</style>