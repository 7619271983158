import remote from '../../remote'
import {_pager, _pagerkey} from '../utils'
import { DateTime } from 'luxon'

const current_year = DateTime.now().year

const easter = (Y) => {
    let C = Math.floor(Y/100)
    let N = Y - 19*Math.floor(Y/19)
    let K = Math.floor((C - 17)/25)
    let I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15
    I = I - 30*Math.floor((I/30))
    I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11))
    let J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4)
    J = J - 7*Math.floor(J/7)
    let L = I - J
    let M = 3 + Math.floor((L + 40)/44)
    let D = (L + 28 - 31*Math.floor(M/4))
    return [D, M, Y]
}

const holidays = () => {
    return [
        {id: "newyearday",date: {day:1, months:[1]}, name: "New Year's Day", event_type: "holiday"},
        {id: "familyday",date: {dow:[1], months:[2], mode: 'order', order: [3], order_target: 'dow'}, name: "Family Day", event_type: "holiday"},
        {id: "goodfri",date: (Y) => {
            let [D, M] = easter(Y)
            return {day:D - 2, months:[M]}
        }, name: "Good Friday", event_type: "holiday"},
        {id: "eastersun",date: (Y) => {
            let [D, M] = easter(Y)
            return {day:D, months:[M]}
        }, name: "Easter Sunday", event_type: "holiday"},
        {id: "victoria",date: {dow:[1], months:[5], mode: 'before', limit_day: 25}, name: "Victoria Day", event_type: "holiday"},
        {id: "canada",date: {day:1, months:[7]}, name: "Canada Day", event_type: "holiday"},
        {id: "saskatchewan",date: {dow:[1], months:[8], mode: 'order', order: [1], order_target: 'dow'}, name: "Saskatchewan Day", event_type: "holiday"},
        {id: "labour",date: {dow:[1], months:[9], mode: 'order', order: [1], order_target: 'dow'}, name: "Labour Day", event_type: "holiday"},
        {id: "thanks",date: {dow:[1], months:[10], mode: 'order', order: [2], order_target: 'dow'}, name: "Thanksgiving", event_type: "holiday"},
        {id: "remembrance",date: {day:11, months:[11]}, name: "Remembrance Day", event_type: "holiday"},
        {id: "xmas",date: {day:25, months:[12]}, name: "Christmas Day", event_type: "holiday"},
        {id: "boxing",date: {day:26, months:[12]}, name: "Boxing Day", event_type: "holiday"},
        {id: "motherday",date: {dow:[7], months:[5], mode: 'order', order: [2], order_target: 'dow'}, name: "Mother's Day", event_type: "holiday"},
        {id: "fatherday",date: {dow:[7], months:[6], mode: 'order', order: [3], order_target: 'dow'}, name: "Father's Day", event_type: "holiday"},
        {id: "halloween",date: {day:31, months:[10]}, name: "Halloween", event_type: "holiday"},
        {id: "stpatrickday",date: {day:17, months:[3]}, name: "Saint Patrick's Day", event_type: "holiday"},
    ]
}

const state = () => {
    return {
        meetings: [],
        family_events: [],
        family_cards: [],
        holidays: [...holidays()],
        pagers: {
            meetings: {},
            family_events: {},
            family_cards: {},
            all_events: {}
        }
    }
}

const getters = {
    getHolidays(state){
        return (year = current_year) => {
            return state
                .holidays
                .map((hday) => {
                    return (typeof hday.date == 'function')?{...hday, date: hday.date(year)}:hday
                })
        }
    },
    getFamilyEvents(state){
        return state.family_events
    },
    getCalendarEvents(state){
        return state.meetings
    },
    getFamilyCards(state){
        return state.family_cards
    },
    getEventsPager(state){
        return (type) => {
            return state.pagers[type]
        }
    }
}

const mutations = {
    mergePager(state, {target, pager, key}){

        if(typeof pager != 'undefined'){
            state.pagers[target] = { 
                ...state.pagers[target],
                ...pager,
                'current':pager[key],
                'current_key': key
            }    
        }

    },
    addHolidays(state, data){

        if(data instanceof Array){
            let _holidays = [
                ...state.holidays.filter(
                    (i) => typeof data
                        .find(j => j.id == i.id ) == 'undefined'
                ),
                ...data
            ]
            state.holidays = _holidays
        }else{
            let _holidays = [
                ...state.holidays.filter(
                    (i) => data.id != i.id
                ),
                data
            ]
            state.holidays = _holidays
        }

    },
    addFamilyEvents(state, data){

        if(data instanceof Array){
            let _family_events = [
                ...state.family_events.filter(
                    (i) => typeof data
                        .find(j => j.id == i.id ) == 'undefined'
                ),
                ...data
            ]
            state.family_events = _family_events
        }else{
            let _family_events = [
                ...state.family_events.filter(
                    (i) => data.id != i.id
                ),
                data
            ]
            state.family_events = _family_events
        }

    },
    addCalendarEvents(state, data){

        if(data instanceof Array){
            let _meetings = [
                ...state.meetings.filter(
                    (i) => typeof data
                        .find(j => j.id == i.id ) == 'undefined'
                ),
                ...data
            ]
            state.meetings = _meetings
        }else{
            let _meetings = [
                ...state.meetings.filter(
                    (i) => data.id != i.id
                ),
                data
            ]
            state.meetings = _meetings
        }

    },
    addFamilyCards(state, data){
        
        if(data instanceof Array){
            let _family_cards = [
                ...state.family_cards.filter(
                    (i) => typeof data
                        .find(j => j.family_card_id == i.family_card_id ) == 'undefined'
                ),
                ...data
            ]
            state.family_cards = _family_cards
        }else{
            let _family_cards = [
                ...state.family_cards.filter(
                    (i) => data.family_card_id != i.family_card_id
                ),
                data
            ]
            state.family_cards = _family_cards
        }

    },
    familyCardDetails(state, details){
        state.family_cards = state.family_cards.map((card) => {
            if(card.family_card_id == details.family_card_id){
                return {...card, ...details}
            }else return card
        })
    },
    removeHoliday(state, id){
        state.holidays = state.holidays.filter(i => i.id != id)
    },
    removeCalendarEvent(state, id){
        state.meetings = state.meetings.filter(i => i.id != id)
    },
    removeFamilyEvent(state, {id, card_id}){

        let card = state.family_cards.find(i => i.family_card_id == card_id)
        card.events = card.events.filter(i => i.id != id)
        state.family_cards = [
            ...state.family_cards.filter(i => i.family_card_id != card_id),
            card
        ]
        state.family_events = state.family_events.filter(i => i.id != id)
    },
    removeFamilyCard(state, id){
        let card = state.family_cards.find(i => i.family_card_id == id)
        state.family_events = state.family_events.filter(i => typeof card.events.find((e) => e.id == i.id) == 'undefined' )
        state.family_cards = state.family_cards.filter(i => i.family_card_id != card.family_card_id)
    }
}

const actions = {
    async fetchFamilyEvents({rootState, dispatch, commit}, options){

        if(/(admin|canvasser|sponsor|family)/.test(rootState.auth.user.role)){
            if(typeof options == 'undefined'){

                let { data, error } = await remote.rpc('list_family_events')
    
                if(error == null)
                    commit('addFamilyEvents', data)
                else
                    console.log(error)
    
            }else{
    
                const {from, to, family_id, sponsor_id, canvasser_id} = options
    
                let { data, error } = await remote.rpc('list_family_events', {
                    from, to, family_id, sponsor_id, canvasser_id
                })
    
                if(error == null)
                    commit('addFamilyEvents', data)
                else
                    console.log(error)
    
            }
        }

    },
    fetchCalendarEvents({state, dispatch, commit}, options = {}){

        return new Promise((resolve, reject) => {

            let getpager = () => {
                let _key = _pagerkey(options)
                let _props = state.pagers.meetings[_key]
                let _pager = { items: 0, total: 1, ..._props }
                if(_pager.items > 0 && _key != state.pagers.meetings.current_key){
                    _pager.offset = 0
                    _pager.items = 0
                }  
                return _pager
            }
            let pager = getpager()

            let process = () => {

                remote
                    .rpc('list_calendar_events', {
                        json_args: {
                            ...options,
                            offset: pager.items
                        }
                    })
                    .then( ({ data, error }) => {
                        if(error == null){

                            let request = _pager(data)
                                            .then((key) => {
                                                return state.pagers.meetings[key]
                                            })
                            
                            commit('mergePager', {target:'meetings', pager:request.pager, key:request.key})
                            commit('addCalendarEvents', request.data)
                            pager = getpager()

                            if(
                                options != null &&
                                options.all &&
                                pager.items < pager.total
                            ){
                                process({done: pager.items == pager.total, data: request.data, error})
                            }else{
                                resolve(pager.items == pager.total)
                            }

                        }else{
                            console.log(error)
                            reject()
                        }
                    })

            }

            if(pager.items < pager.total){
                process()
            }else{
                resolve({done: true})
            }

        })

    },
    async removeFamilyEvent({dispatch, commit}, {id, card_id}){

        let { error } = await remote.rpc('remove_family_event', {_id: id})

        if(error == null){
            commit('removeFamilyEvent', {id, card_id})
        }else{
            console.log(error)
        }
    },
    async setFamilyCard({dispatch, commit}, {options, callback}){

        return new Promise((resolve, reject) => {
            remote
                .rpc('set_family_card', { json_args: options })
                .then(({data, error}) => {
                    if(error == null){
                        remote
                            .rpc('get_family_card', {family_card_id: data, _not_active: null})
                            .then(({data, error}) => {
                                if(error == null){
                                    commit('addFamilyCards', data)
                                    resolve({done: true, data, error})
                                }else{
                                    reject(error)
                                }
                            })
                    }else{
                        reject(error)
                    }
                })
        })

    },
    setCalendarEvents({dispatch, commit}, { options }){

        return new Promise((resolve, reject) => {
            remote
                .rpc('set_calendar_event', { json_args: options })
                .then(({data, error}) => {
                    if(error == null){
                        remote
                            .rpc('get_calendar_event', {_id: data})
                            .then(({data, error}) => {
                                if(error == null){
                                    commit('addCalendarEvents', data)
                                    resolve({done: true, data, error})
                                }else{
                                    reject(error)
                                }
                            })
                    }else{
                        reject(error)
                    }
                })
        })

    },
    fetchFamilyCard({state, dispatch, commit}, options = {}){

        return new Promise((resolve, reject) => {

            let getpager = () => {
                let _key = _pagerkey(options)
                let _props = state.pagers.family_cards[_key]
                let _pager = { items: 0, total: 1, ..._props }
                if(_pager.items > 0 && _key != state.pagers.family_cards.current_key){
                    _pager.offset = 0
                    _pager.items = 0
                }    
                return _pager
            }
            let pager = getpager()

            let process = () => {
                remote
                    .rpc('list_family_cards', {
                        json_args: {
                            ...options,
                            offset: pager.items
                        }
                    })
                    .then(({data, error}) => {
                        if(error == null){

                            let request =   _pager(data)
                                            .then((key) => {
                                                return state.pagers.family_cards[key]
                                            })
                            
                            commit('mergePager', {target:'family_cards', pager:request.pager, key:request.key})
                            commit('addFamilyCards', request.data)
                            pager = getpager()

                            if(
                                options != null &&
                                options.all &&
                                pager.items < pager.total
                            ){
                                process()
                            }else{
                                resolve({done: pager.items == pager.total, data: request.data, error})
                            }

                        }else{
                            reject(error)
                        }
                    })
            }

            if(pager.items < pager.total){

                process()

            }else{
                resolve({done: true})
            }
    
        })

    },
    familyCardDetails({dispatch, commit}, id){

        return new Promise( async (resolve, reject) => {

            let { data, error } = await remote.rpc('get_family_card', { family_card_id: id, _not_active: null })

            if(error == null){
                commit('familyCardDetails', data)
                resolve(data)
            }
            else{
                console.log(error)
                reject(error)
            }
    
        })

    },
    fetchEvents({state, dispatch, commit}, options = {}){
    
        return new Promise((resolve, reject) => {

            let getpager = () => {
                let _key = _pagerkey(options)
                let _props = state.pagers.all_events[_key]
                let _pager = { items: 0, total: 1, ..._props }
                if(_pager.items > 0 && _key != state.pagers.all_events.current_key){
                    _pager.offset = 0
                    _pager.items = 0
                }
                return _pager
            }
            let pager = getpager()

            let process = async () => {
                let {data, error} = await remote.rpc('list_all_events', {
                                        json_args: { ...options, offset: pager.items }
                                    })
                
                if(error == null){

                    let request =   _pager(data)
                                    .then((key) => {
                                        return state.pagers.all_events[key]
                                    })
                    
                    commit('mergePager', {target:'all_events', pager:request.pager, key:request.key})
                    commit('addCalendarEvents', request.data.filter( i => i.event_type=='event' ))
                    commit('addFamilyEvents', request.data.filter( i => i.event_type!='event' ))
                    pager = getpager()

                    if(
                        options != null &&
                        options.all &&
                        pager.items < pager.total
                    ){
                        process()
                    }else{
                        resolve({done: pager.items == pager.total, data: request.data, error})
                    }

                }else{
                    reject(error)
                }
            }

            if(pager.items < pager.total){

                process()

            }else{
                resolve({done: true})
            }
    
    
        })

    },
    removeFamilyCard({state, dispatch, commit}, id){
        return new Promise((resolve, reject) => {
            remote
                .rpc('remove_family_card', {
                    _id: id
                })
                .then((response) => {
                    resolve(response)
                    commit('removeFamilyCard', id)
                })
        })
    },
    removeCalendarEvent({state, dispatch, commit}, id){
        return new Promise((resolve, reject) => {
            remote
                .rpc('remove_calendar_event', {
                    _id: id
                })
                .then((response) => {
                    resolve(response)
                    commit('removeCalendarEvent', id)
                })
        })
    }
}

export default {
    state, getters, mutations, actions
}