<template>
    <section id="admins-management" class="h-100 d-flex flex-column position-relative">
        <div class="pb-2 d-flex flex-row justify-content-between">
            <h2>Families</h2>
            <button class="btn btn-success" @click.prevent="add">New family</button>
        </div>
        <ag-grid-vue
            class="ag-theme-alpine flex-fill"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :suppressCellSelection="true"
            rowSelection="single"
            @rowClicked="edit">
        </ag-grid-vue>
        <form ref="editor" class="editor" :class="{'d-none':!show_editor}">
            <div
                v-if="editor_mode=='new'"
                class="d-flex flex-row justify-content-between">
                <h4>New family</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <div
                v-if="editor_mode=='edit'"
                class="d-flex flex-row justify-content-between">
                <h4>Edit family</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <fieldset class="flex-fill d-flex flex-column justify-content-center">
                <ul v-if="editor_mode=='edit'">
                    <li>ID: {{selection.id}}</li>
                </ul>
                <input class="w-100 mb-3" type="name" name="name" placeholder="name" :value="selection.family_name || ''">
                <button class="btn btn-primary submit" @click.prevent="submit">Submit</button>
            </fieldset>
        </form>
    </section>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
export default {
    data(){
        return {
            columnDefs: [],
            rowData: [],
            show_editor: false,
            editor_mode: '',
            selection: {},
            subscription: undefined
        }
    },
    computed: {
        families(){
            return this.$store.getters.getFamilies
        },
        profile(){
            return this.$store.getters.getUserInfo
        }
    },
    methods: {
        add(){
            this.show_editor = true
            this.editor_mode = 'new'
        },
        edit(row){
            this.show_editor = true
            this.editor_mode = 'edit'
            this.selection = row.data
        },
        submit(){
            this.show_editor = false
            let _new = {
                family_name: this.$refs.editor['name'].value
            }
            if(this.editor_mode == 'edit')
                _new = {..._new, ...{id: this.selection.id}}
            this.$store.dispatch('setFamilyDetails', _new)
            this.selection = {}
        },
        close(){
            this.show_editor = false
            this.selection = {}
        }
    },
    components: {
        AgGridVue
    },
    created(){
        this.$store.dispatch('fetchFamilies')
    },
    beforeMount() {
        this.columnDefs = [
            { field: 'id' },
            { field: 'family_name', headerName: 'name' }
        ]
        this.rowData = this.families
        this.subscription = this.$store.subscribe((mutations, state) => {
            this.rowData = state.family.list;
        })
    },
    beforeUnmount(){
        this.subscription()
    }
}
</script>

<style lang="scss" scoped>
.editor{
    display:flex;
    position:absolute;
    flex-direction:column;
    justify-content:between;
    align-content:center;
    bottom:15%;
    left:15%;
    width:70%;
    height:70%;
    border:1px solid lightgray;
    background-color:white;
    padding:20px 40px;
    .submit{
        width:min-content;
    }
}
</style>