<template>
    <section id="admins-management" class="h-100 d-flex flex-column position-relative">
        <div class="pb-2 d-flex flex-row justify-content-between">
            <h2>Canvassers</h2>
            <button class="btn btn-success" @click.prevent="add">New canvasser</button>
        </div>
        <ag-grid-vue
            class="ag-theme-alpine flex-fill"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :suppressCellSelection="true"
            rowSelection="single"
            @rowClicked="edit">
        </ag-grid-vue>
        <form ref="editor" class="editor" :class="{'d-none':!show_editor}">
            <div
                v-if="editor_mode=='new'"
                class="d-flex flex-row justify-content-between">
                <h4>New canvasser</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <div
                v-if="editor_mode=='edit'"
                class="d-flex flex-row justify-content-between">
                <h4>Edit canvasser</h4>
                <button class="btn btn-danger" @click.prevent="close">Cancel</button>
            </div>
            <fieldset class="flex-fill d-flex flex-column justify-content-center">
                <ul v-if="editor_mode=='edit' && profile.role=='admin'">
                    <li>ID: {{selection.id}}</li>
                    <li>uid: {{selection.uid}}</li>
                </ul>
                <input class="w-100 mb-3" type="name" name="name" placeholder="name">
                <input class="w-100 mb-3" type="email" name="email" placeholder="email">
                <Autocomplete
                    name="sponsor"
                    v-model="sponsor"
                    v-if="profile.role == 'admin'"
                    forceSelection
                    :suggestions="sponsorsFiltered"
                    @complete="($event) => {
                        sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                    }"
                    field="name"
                    class="mb-3"></Autocomplete>
                <button class="btn btn-primary submit" @click.prevent="submit">Submit</button>
            </fieldset>
        </form>
    </section>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
export default {
    data(){
        return {
            columnDefs: [],
            rowData: [],
            show_editor: false,
            editor_mode: '',
            selection: {},
            sponsor: null,
            sponsorsFiltered: null,
            subscription: undefined
        }
    },
    computed: {
        sponsors(){
            return this.$store.getters.getSponsors
        },
        canvassers(){
            return this.$store.getters.getCanvassers
        },
        profile(){
            return this.$store.getters.getUserInfo
        }
    },
    methods: {
        add(){
            this.show_editor = true
            this.editor_mode = 'new'
            this.$refs.editor['name'].value = ''
            this.$refs.editor['email'].value = ''
            this.sponsor = null
        },
        edit(row){
            this.show_editor = true
            this.editor_mode = 'edit'
            this.selection = row.data
            this.$refs.editor['name'].value = this.selection.name
            this.$refs.editor['email'].value = this.selection.email
            this.sponsor = { id: this.selection.sponsor_id, name: this.selection.sponsor }
        },
        submit(){
            this.show_editor = false
            let _new = {
                name: this.$refs.editor['name'].value,
                sponsor_id: this.profile.sponsor_id || this.sponsor.id,
                role: 'canvasser'
            }
            let prev = false
            if( this.$refs.editor['email'].value.trim() != '' ){
                let email = this.$refs.editor['email'].value.trim()
                prev = this.canvassers.find(i => i.email == email )
                if(!prev){
                    _new['email'] = email
                }else{
                    _new['uid'] = prev.uid
                }
            }
            if(this.editor_mode == 'edit')
                _new = {..._new, ...{id: this.selection.id}}

            if(!prev && typeof _new['email'] != 'undefined'){
                this.$store.dispatch('invite', _new)
                .then(() => {
                    this.$store.dispatch('fetchCanvassers')
                    .then(() => {
                        this.rowData = this.canvassers
                    })
                })
            }else{
                this.$store.dispatch('setCanvasser', _new)
                .then(() => {
                    this.$store.dispatch('fetchCanvassers')
                    .then(() => {
                        this.rowData = this.canvassers
                    })
                })
            }
            this.selection = {}
        },
        close(){
            this.show_editor = false
            this.selection = {}
        }
    },
    components: {
        AgGridVue
    },
    created(){
        this.$store.dispatch('fetchCanvassers')
        this.$store.dispatch('fetchSponsors')
    },
    beforeMount() {
        this.columnDefs = [
            { field: 'name' },
            { field: 'sponsor' },
            { field: 'email' },
            { field: 'details' }
        ]
        if(this.profile.role == 'admin'){
            this.columnDefs = [{ field: 'id' }, { field: 'uid' }, ...this.columnDefs]
            this.sponsorColumnDefs = [
                { field: 'name' },
                { field: 'email' }
            ]
        }
        if(this.profile.role == 'sponsor')
            this.sponsor = this.profile.id
        this.rowData = this.canvassers
        this.subscription = this.$store.subscribe((mutations, state) => {
            this.rowData = state.canvasser.list
        })
    },
    beforeUnmount(){
        this.subscription()
    }
}
</script>

<style lang="scss" scoped>
.editor{
    display:flex;
    position:absolute;
    flex-direction:column;
    justify-content:between;
    align-content:center;
    bottom:15%;
    left:15%;
    width:70%;
    height:70%;
    border:1px solid lightgray;
    background-color:white;
    padding:20px 40px;
    .submit{
        width:min-content;
    }
    .sponsor-list{
        width:100%;
        height:150px;
    }
}
</style>