import remote from '../remote'

const state = () => {
    return {
        user: {},
        signedIn: false
    }
}

const getters = {
    getUserInfo(state){
        return state.user
    },
    isSignedIn(state){
        return state.signedIn
    }
}

const mutations = {
    setUserInfo(state, info){
        state.user = info || {}
    },
    signedIn(state, status){
        state.signedIn = status
    }
}

const actions = {
    signUp({dispatch, commit}, {email, password}){

        if(remote.auth.session() != null) return;
        if(email == '' || email == null || typeof email == 'undefined'){
            dispatch('setError', {category: 'auth', title: 'invalid email', message: 'please enter a valid email address'})
            return;
        }
        if(password == '' || password == null || typeof password == 'undefined'){
            dispatch('setError', {category: 'auth', title: 'invalid password', message: 'please enter a valid password'})
            return;
        }

        return new Promise((resolve, reject) => {

            remote.auth.signUp({
                email,
                password
            }).then(({error}) => {
    
                //if(error == null){
    
                    //remote.rpc('set_user').then(({ data, error }) => {
                    
                        if(error == null){ 
                            //dispatch('setInfo', {category: 'auth', title: 'Please check your email', message: 'Please click the link in the confirmation email sent to you.'})
                            resolve(true)
                            //dispatch('load')
                        }else{
                            //dispatch('setError', {category: 'auth', title: 'set_user error', message: error.message})
                        //}
                    
                    //})
                
                //}else{
                    dispatch('setError', {category: 'auth', title: 'SignUp error', message: error.message})
                    reject(false)
                }
            
            })
    
        })

    },
    async signIn({dispatch, commit}, {email, password}){

        if(remote.auth.session() != null) return;
        if(email == '' || email == null || typeof email == 'undefined'){
            dispatch('setError', {category: 'auth', title: 'invalid email', message: 'please enter a valid email address'})
            return;
        }
        if(password == '' || password == null || typeof password == 'undefined'){
            dispatch('setError', {category: 'auth', title: 'invalid password', message: 'please enter a valid password'})
            return;
        }

        const { user, error } = await remote.auth.signIn({
            email,
            password
        })

        if(error == null){
            dispatch('load')
        }else{
            dispatch('setError', {category: 'auth', title: 'SignIn error', message: error.message})
        }

    },
    recover({dispatch, commit}, {email}){

        return new Promise(async (resolve, reject) => {

            if(email == '' || email == null || typeof email == 'undefined'){
                dispatch('setError', {category: 'auth', title: 'invalid email', message: 'please enter a valid email address'})
                reject()
                return;
            }
    
            let { data, error } = await remote.auth.api.resetPasswordForEmail(email)
    
            if(error == null){
                resolve(data)
            }else{
                dispatch('setError', {category: 'auth', title: 'SignIn error', message: error.message})
                reject(error)
            }
    
        })

    },
    invite({dispatch, commit}, {id, name, family, sponsor_id, role, email}){

        return new Promise(async (resolve, reject) => {

            let request = await fetch(`${process.env.VUE_APP_SERVICE_URL}/invite`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'token': remote.auth.session().access_token,
                    'target': process.env.VUE_APP_ENV
                },
                body: JSON.stringify({
                    id, email, role, name, family, sponsor_id
                })
            })

            let data = await request.json()
    
            if(request.ok){
                resolve(data.uid)
            }else{
                dispatch('setError', {
                    category: 'auth',
                    title: 'Invite error',
                    message: 'There is an issue sending the invitation'
                })
                reject()
            }
    
        })
        
    },
    async updatePassword({dispatch, commit}, {password}){

        if(remote.auth.session() == null) return;
        if(password == '' || password == null || typeof password == 'undefined'){
            dispatch('setError', {category: 'auth', title: 'invalid password', message: 'please enter a valid password'})
            return;
        }

        const { user, error } = await remote.auth.update({
            password
        })

        if(error == null){
            //dispatch('load')
        }else{
            dispatch('setError', {category: 'auth', title: 'Update error', message: error.message})
        }

    },
    async signOut({dispatch, commit}){

        const { error } = await remote.auth.signOut()
        if(error == null){
            dispatch('unload')
        }else{
            dispatch('setError', {category: 'auth', title: 'SignOut error', message: error.message})
        }

    },
    async syncUserInfo({dispatch, commit}){

        commit('setUserInfo', {loading:true})

        let { data, error } = await remote.rpc('get_profile_details')

        if(error == null){
            commit('setUserInfo', data)
        }else{
            dispatch('setError', {category: 'auth', title: 'User Info error', message: error})
        }

    },
    checkCredentials({dispatch, commit}){
        remote.auth.onAuthStateChange((event, session) => {
            if(session != null) dispatch('load')
        })
        if(remote.auth.session() != null){
            dispatch('load')
        }
    },
    signedIn({commit}, {status}){
        commit('signedIn', status)
    }
}

export default {
    state, getters, mutations, actions
}