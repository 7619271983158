<template>
    <span>{{label}}</span>
</template>

<script>
import { encodeDate } from '../../../utils'

export default {
    data: function () {
        return {
            label: null
        }
    },
    beforeMount() {
        this.label = encodeDate(this.params.value)
    },
    methods: {
        refresh(params) {
            this.label = encodeDate(params.value)
        }
    }
}
</script>

<style>
</style>