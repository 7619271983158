<template>
<main @click="globalClick">
<Nav class="header" />
<RouterView class="content" />
</main>
</template>

<script>
import Nav from './components/Nav'
import { RouterView } from 'vue-router'
import { hashParams } from './utils'

export default {
  name: 'App',
  components: {
    RouterView,
    Nav
  },
  methods:{
    globalClick(e){
      this.$emmiter.emit('globalClick',e)
    }
  },
  beforeMount(){
    let hash = hashParams()
    if(hash.access_token && hash.type == 'recovery')
      this.$router.push('/setpass')
    if(hash.access_token && hash.type == 'magiclink')
      this.$router.push('/setpass')
    if(hash.access_token && hash.type == 'invite')
      this.$router.push('/setpass')
    if(hash.error_code && hash.error_description)
      this.$router.push('/login')
  },
  created(){
    this.$store.dispatch('checkCredentials')
  }
}
</script>

<style lang="scss">
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../node_modules/primevue/resources/primevue.min.css";
@import "../node_modules/primevue/resources/themes/saga-blue/theme.css";
@import "bootstrap/scss/bootstrap";
$header-size:50px;

html, body, #app, main{
  width:100%;
  height:100%;
  max-height:100%;
  max-width:100%;
  width:100vw;
  height:100vh;
}
main{
  .header{
    height:$header-size;
  }
  .content{
    $height: calc(100vh - #{$header-size}); 
    height:$height;
    &, & .row, & .row > *, & .row > * > *
    {
      max-height:$height;
    }
  }
}
</style>
