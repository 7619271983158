<template>
    <form>
        <fieldset v-if="!no_email" class="mb-3">
        <input type="text" class="form-control" name="email" ref="email" placeholder="Email">
        </fieldset>
        <fieldset v-if="!no_password" class="mb-3">
        <input type="password" class="form-control" name="password" ref="password" placeholder="Password">
        </fieldset>
        <fieldset v-if="!no_password && confirm_password" class="mb-3">
        <input type="password" class="form-control" name="password_confirmation" ref="password_confirmation" placeholder="Confirm password">
        </fieldset>
        <p v-if="confirm_password">{{error}}</p>
        <button class="btn btn-info" @click.prevent="submit">Submit</button>
    </form>
</template>

<script>
export default {
    props: ['confirm_password', 'no_email', 'no_password'],
    data: function(){
        return {
            error: ''
        }
    },
    methods: {
        submit: function(){
            if(
                this.confirm_password === true &&
                this.$refs.password.value != this.$refs.password_confirmation.value
            ){
                this.error = 'password and its confirmation do not match'
                return
            }
            if(this.no_email)
                this.$emit('submit', this.$refs.password.value)
            if(this.no_password)
                this.$emit('submit', this.$refs.email.value)
            else
                this.$emit('submit', this.$refs.email.value, this.$refs.password.value)
        }
    }
}
</script>

<style></style>