<template>
    <div class="d-flex flex-column justify-content-center h-100">
        <button class="btn btn-danger event-remove d-flex flex-column justify-content-center" @click.prevent="buttonClicked()">-</button>
    </div>
</template>

<script>
import { emmiter } from '../../../main.js'

export default {
    data(){
        return {
            _id: null
        }
    },
    methods: {
        refresh(params) {
        },
        buttonClicked() {
            emmiter.emit('removeEvent',this._id)
        }
    },
    mounted(){
        this._id = this.params.data._id
    }
}
</script>

<style>
.btn.event-remove{
    width:30px;
    height: 20px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>