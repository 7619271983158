import remote from '../remote'

const state = () => {
    return {
        list: []
    }
}

const getters = {
    getSponsors(state){
        return state.list
    }
}

const mutations = {
    setSponsorsList(state, list){
        state.list = list
    }
}

const actions = {
    fetchSponsors({rootState, dispatch, commit}){
        return new Promise(async (resolve, reject) => {

            if(/admin/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('list_sponsors')

                if(error == null){
                    commit('setSponsorsList', data)
                    resolve()
                }else reject(error)

            }else reject('unauthorized')
            
        })
    },
    setSponsor({rootState, dispatch, commit}, sponsor){
        return new Promise(async (resolve, reject) => {

            let {
                id,
                uid,
                name,
                address,
                details,
                billing_details,
                billing_address
            } = sponsor
            if(/admin/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('set_profile', { 'options': {
                    id,
                    uid,
                    name,
                    address,
                    details,
                    billing_details,
                    billing_address,
                    profile_type: 'sponsor'
                    }
                })

                if(error == null){
                    resolve()
                }else reject(error)

            }else reject('unauthorized')

        })
    }
}

export default {
    state, getters, mutations, actions
}