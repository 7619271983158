import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import PrimeVue from 'primevue/config'
import Autocomplete from 'primevue/autocomplete'
import MultiSelect from 'primevue/multiselect'
import mitt from 'mitt'

export const emmiter = mitt()
const app = createApp(App)
app.config.globalProperties.$emmiter = emmiter
app.use(router)
app.use(store)
app.use(PrimeVue)
app.use(SetupCalendar, {})
app.component('Calendar', Calendar)
app.component('DatePicker', DatePicker)
app.component('Autocomplete', Autocomplete)
app.component('Multiselect', MultiSelect)
app.mount('#app')
