<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">

          <router-link tag="a" to="/" class="navbar-brand">Care Community Calendar</router-link>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">

              <li class="nav-item" v-if="signedIn && isOwner">
                <router-link tag="a" to="/meetings" class="nav-link" aria-current="page" href="#">Meetings & Events</router-link>
              </li>

              <li v-if="signedIn && isManager" class="nav-item">
                <router-link tag="a" to="/familycards" class="nav-link" aria-current="page" href="#">Family Cards</router-link>
              </li>

              <li v-if="signedIn && profile.role == 'admin'" class="nav-item">
                <router-link tag="a" to="/calendar" class="nav-link" aria-current="page" href="#">Calendar</router-link>
              </li>

              <li v-if="!signedIn" class="nav-item">
                <router-link tag="a" to="/signup" class="nav-link" aria-current="page">Sign Up</router-link>
              </li>

              <li v-if="!signedIn" class="nav-item">
                <router-link tag="a" to="/login" class="nav-link" aria-current="page">Sign In</router-link>
              </li>

              <Dropdown mode="hover" tag="li" v-if="signedIn">
                <template v-slot:button>
                  <span v-if="profile.loading">Loading...</span>
                  <span v-else>{{profile.email}} [{{profile.role}}]</span>
                </template>
                <template v-slot:links>
                  <li v-if="profile.role == 'admin'"><router-link tag="a" to="/migrate" class="dropdown-item">Migrate</router-link></li>
                  <li v-if="isManager"><router-link tag="a" to="/manage" class="dropdown-item">Manage</router-link></li>
                  <li v-if="isManager"><hr class="dropdown-divider"></li>
                  <li><router-link tag="a" to="/profile" class="dropdown-item">Profile</router-link></li>
                  <li><a class="dropdown-item" @click="signout">Sign out</a></li>
                </template>
              </Dropdown>
            </ul>
          </div>
        </div>
    </nav>
</template>

<script>
import Dropdown from './Dropdown'
import authMixin from '../mixins/auth'

export default {
  mixins: [authMixin],
  methods: {
    signout: function(){
      this.$store.dispatch('signOut')
      this.$router.push('/')
    }
  },
  components: {
    Dropdown
  }
}
</script>

<style>
</style>