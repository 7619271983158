<template>
    <section class="container-fluid">
        <div class="row h-100 align-items-center">
            <div class="col-12">
                <h2 class="text-center">Orders</h2>                
            </div>
        </div>
    </section>
</template>

<script>

export default {
}
</script>

<style></style>