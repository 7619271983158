<template>
    <select class="form-select" v-model="value">
        <option :value="null">Select month</option>
        <option v-for="month, i in months" :value="i + 1">{{month}}</option>
    </select>
</template>

<script>
export default {
    data(){
        return {
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            value: null
        }
    }
}
</script>

<style></style>