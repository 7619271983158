<template>
    <section class="container-fluid">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-9 h-100 py-2">
                <Calendar
                    ref="calendar"
                    :masks="masks"
                    :attributes="current"
                    @update:to-page="_update"
                    :max-date="max_date"
                    :min-date="min_date"
                    is-expanded>

                    <template v-slot:day-content="{ day, attributes }">
                      <div class="h-100">
                        <span class="day-label">{{ day.day }}</span>
                        <div class="day-schedule">
                          <p v-for="attr in attributes" :key="attr.key" :order="attr.order">
                            <span v-if="attr?.customData?.event_type == 'Memorial'">{{memorial}}</span>
                            <span v-if="attr?.customData?.event_type == 'Birthday'">{{birthday}}</span>
                            <span v-if="attr?.customData?.event_type == 'Aniversary'">{{anniversary}}</span>
                            <span v-if="attr?.customData?.event_type == 'event'">{{event}}</span>
                            <span v-if="attr?.customData?.event_type == 'holiday'">{{holiday}}</span>
                            <span class="ms-1">{{attr?.customData?.name}}{{attr?.customData?.family_member }}</span>
                          </p>
                        </div>
                      </div>
                    </template>

                </Calendar>
            </div>
            <div class="col-md-3 h-100 py-2">
                <button
                    class="btn w-100 mb-1"
                    :class="{'btn-info':!loading,'btn-secondary':loading,'disabled':loading}"
                    @click="export">
                        {{loading||'Print'}}
                </button>
                <Autocomplete
                    name="sponsor"
                    v-model="sponsor"
                    class="w-100 mb-1"
                    forceSelection
                    :suggestions="sponsorsFiltered"
                    @complete="($event) => {
                        sponsorsFiltered = sponsors.filter( i => RegExp(`.*${$event.query.toLowerCase()}.*`).test(i['name'].toLowerCase()) )
                    }"
                    field="name"
                    placeholder="Sponsor"
                    @item-select="_watcher"
                    @item-unselect="_watcher"
                    @clear="_watcher"></Autocomplete>
                <Autocomplete
                    name="order"
                    v-model="order"
                    class="w-100 mb-1"
                    forceSelection
                    :suggestions="ordersFiltered"
                    @complete="($event) => {
                        ordersFiltered = orders
                            .filter( i => (sponsor != null)?(i.sponsor_id == sponsor.id):true )
                            .filter( i => RegExp(`.*${$event.query}.*`).test(i['id'])  )
                    }"
                    field="id"
                    placeholder="Orders"
                    @item-select="_watcher"
                    @item-unselect="_watcher"
                    @clear="_watcher"></Autocomplete>
                <Dropdown class="w-100 mb-1" v-model="startMonth" :options="monthOptions" optionGroupLabel="label" optionLabel="label" optionGroupChildren="items" @change="_setYear" placeholder="Starting month" />
                <Dropdown class="w-100 mb-1" v-model="endMonth" :options="monthOptions" optionGroupLabel="label" optionLabel="label" optionGroupChildren="items" @change="_setYear" placeholder="Ending month" />
                <Dropdown class="w-100 mb-1" v-model="font" :options="available_fonts" optionLabel="label" optionValue="value" placeholder="Ending month" />
                <SelectButton class="w-100 mb-1" v-model="sort_by" :options="sortOptions" aria-labelledby="basic" />
                <input type="number" class="w-100 mb-1" v-model="events_font_size" placeholder="event's font size (6.2)" />
                <input type="number" class="w-100 mb-1" v-model="event_lines_limit" placeholder="lines limit per day (12)" />
                <input type="number" class="w-100 mb-1" v-model="events_long_line" placeholder="event long line limit (17)" />
                <input type="number" class="w-100 mb-1" v-model="char_spacing" placeholder="char spacing (0)" step="0.01" max="1" />
                <div class="d-flex w-100 mb-1">
                    <InputSwitch v-model="events_at_bottom" />
                    <label>&nbsp;&nbsp;&nbsp;Events at bottom</label>
                </div>
                <div class="d-flex w-100 mb-1">
                    <InputSwitch v-model="force_bold_content" />
                    <label>&nbsp;&nbsp;&nbsp;Force bold</label>
                </div>
                <div class="d-flex w-100 mb-1">
                    <InputSwitch v-model="cut_very_long_lines" />
                    <label>&nbsp;&nbsp;&nbsp;Cut very long lines</label>
                </div>
                <div class="d-flex w-100 mb-1">
                    <label>Trim&nbsp;</label>
                    <InputSwitch v-model="trim_family_events" />
                    <label>&nbsp;F.Names&nbsp;</label>
                    <InputSwitch v-model="trim_calendar_events" />
                    <label>&nbsp;Events&nbsp;</label>
                </div>
                <p role="events_label">
                    <span class="me-2">{{birthday || ' '}} Birthday</span>
                    <span class="me-2">{{memorial}} Memorial</span>
                    <span>{{anniversary}} Anniversaries</span><br/>
                    <span class="me-2">{{event}} Meetings</span>
                    <span>{{holiday}} Holiday</span>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import { saveAs } from 'file-saver'
import { getPdf, createDoc, putCalendarPage } from "../pdf"
import authMixin from '../mixins/auth'
import { DateTime } from 'luxon'
import MultiSelect from 'primevue/multiselect'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import SelectButton from 'primevue/selectbutton'
import { decodeDate } from '../utils'

const now = DateTime.now()
const yearItem = year => {
    return {label: year, items: [
        {label: `January ${year}`, year, month: 1, index: Number(`${year}01`) },
        {label: `February ${year}`, year, month: 2, index: Number(`${year}02`) },
        {label: `March ${year}`, year, month: 3, index: Number(`${year}03`) },
        {label: `April ${year}`, year, month: 4, index: Number(`${year}04`) },
        {label: `May ${year}`, year, month: 5, index: Number(`${year}05`) },
        {label: `June ${year}`, year, month: 6, index: Number(`${year}06`) },
        {label: `July ${year}`, year, month: 7, index: Number(`${year}07`) },
        {label: `August ${year}`, year, month: 8, index: Number(`${year}08`) },
        {label: `September ${year}`, year, month: 9, index: Number(`${year}09`) },
        {label: `October ${year}`, year, month: 10, index: Number(`${year}10`) },
        {label: `November ${year}`, year, month: 11, index: Number(`${year}11`) },
        {label: `December ${year}`, year, month: 12, index: Number(`${year}12`) }
    ]}
}
const currentYear = yearItem( DateTime.now().toFormat('yyyy') )
const nextYear = yearItem( DateTime.now().plus({years: 1}).toFormat('yyyy') )

export default {
    data(){
        
        return {
            loading: null,

            subscription: undefined,

            memorial:'†',
            anniversary:'♥',
            birthday:null,
            event: '○',
            holiday: '!',

            masks: {
                weekdays: 'WWW'
            },
            month: currentYear.items[0].month,
            year: currentYear.items[0].year,
            event_lines_limit: null,
            events_font_size: null,
            events_long_line: null,
            char_spacing: null,
            force_bold_content: null,
            cut_very_long_lines: true,
            trim_family_events: true,
            trim_calendar_events: false,

            sponsor: null,
            order: null,

            sponsorsFiltered: null,
            ordersFiltered: null,
            filterOptions: {},

            current: [],
            min_date: null,
            max_date: null,

            font: 'sourcesans',
            available_fonts: [
                {label: 'Arial', value: 'arial'},
                {label: 'Source Sans 3', value: 'sourcesans'},
            ],

            startMonth: currentYear.items[0],
            endMonth: currentYear.items[11],
            monthOptions: [
                currentYear,
                nextYear
            ],
            months: [
                ...currentYear.items,
                ...nextYear.items
            ],
            
            sort_by: 'length',
            sortOptions: ['length', 'alphabetically'],
            events_at_bottom: false,
            holiday_year: currentYear.items[0].year
        }
    },
    computed: {
        orders(){
            return this.$store.getters.getOrders
        },
        sponsors(){
            return this.$store.getters.getSponsors
        },
        family_events(){
            return this.$store.getters.getFamilyEvents
        },
        calendar_events(){
            return this.$store.getters.getCalendarEvents
        },
        holidays: {
            get(){
                return this.$store.getters.getHolidays(this.holiday_year)
            },
            set(year){
                this.holiday_year = year
            }
        }
    },
    methods: {
        _watcher(){
            let options = {}
            if( this.sponsor != null ){ options['sponsor_id'] = this.sponsor.id }
            if( this.order != null ){ options['order_id'] = this.order.id }
            this.filterOptions = options
            this._fetch()
        },
        _getCurrent(){
            let sorting = (a_obj, b_obj) => {
                let a = a_obj.customData
                let b = b_obj.customData

                if(a.event_type == 'Birthday'){
                    if(b.event_type == 'Memorial' || b.event_type == 'Aniversary' || b.event_type == 'event') return -1
                }
                if(a.event_type == 'Memorial'){
                    if(b.event_type == 'Birthday') return 1
                    if(b.event_type == 'Aniversary' || b.event_type == 'event') return -1
                }
                if(a.event_type == 'Aniversary'){
                    if(b.event_type == 'Memorial' || b.event_type == 'Birthday') return 1
                    if(b.event_type == 'event') return -1
                }
                if(a.event_type == 'event'){
                    if(b.event_type == 'Memorial' || b.event_type == 'Birthday' || b.event_type == 'event') return 1
                }
                if(a.event_type == b.event_type){
                    if(a.name < b.name) return -1
                    if(a.name > b.name) return 1
                }
                return 0
            }
            let mapping = (item) => {
                return {
                    dates: decodeDate(item.date, {years: [this.year]}).map( date => DateTime.fromISO(date).toJSDate()),
                    key: item.id,
                    customData: item
                }
            }
            let filter_month = (item) => {
                return ( item.date.months.indexOf(this.month) != -1 )
            }
            let filter_sponsor = (item) => {
                return ( item.sponsor_id == this.sponsor.id )
            }

            let response = [
                ...this.family_events.filter(filter_month).filter(filter_sponsor).map(mapping),
                ...this.calendar_events.filter(filter_month).filter(filter_sponsor).map(mapping),
                ...this.holidays.filter(filter_month).map(mapping)
            ]
            .sort(sorting)
            .map((el, order) => {
                return {...el, order}
            })
            
            return response
        },
        _update(page){
            this.month = page.month
            this.year = page.year
            this._fetch()
        },
        _fetch(){
            let _from = now.set({day: 1, month: this.month, year: this.year})
            let _to = _from.plus({months:1}).minus({days:1})
            if(
                typeof this.filterOptions['sponsor_id'] != 'undefined' ||
                typeof this.filterOptions['order_id'] != 'undefined'
            ){
                this.loading = 'Loading...'
                this.$store
                    .dispatch('fetchEvents', {
                        all:true,
                        from: _from.toSQLDate(), 
                        to: _to.toSQLDate(),
                        ...this.filterOptions})
                    .then(() => {
                        this.current = this._getCurrent()
                        this.loading = null
                    })
            }
        },
        _setYear: function(){
            this.min_date = now.set({
                year: this.startMonth.year,
                month:this.startMonth.month,
                day:1
            }).toJSDate()
            this.max_date = now.set({
                year: this.endMonth.year,
                month:this.endMonth.month,
                day:1
            }).toJSDate()

            this.months = [
                ...currentYear.items,
                ...nextYear.items                
            ].filter(
                item => (item.index >= this.startMonth.index && item.index <= this.endMonth.index)
            )
            if(this.$refs.calendar){
                this.$refs.calendar.showPageRange({ from: this.min_date, to: this.max_date })
                this.$refs.calendar.move(this.min_date)
            }
        },
        _load(){

            this._setYear()
            if(this.profile.role == 'admin'){
                if(this.sponsors.length == 0){ this.$store.dispatch('fetchSponsors') }
                if(this.orders.length == 0){ this.$store.dispatch('fetchOrders') }
            }
    
        },
        export(){
            this.loading = 'Loading...'
            let current = 0
            let filter_month = (item) => {
                return ( item.date.months.indexOf(this.months[current].month) != -1 )
            }
            let filter_sponsor = (item) => {
                return ( item.sponsor_id == this.sponsor.id )
            }
            let process = () => {
                let events = []
                let _from = now.set({day: 1, month: this.months[current].month, year: this.months[current].year})
                let _to = _from.plus({months:1}).minus({days:1})
                this.$store
                    .dispatch('fetchEvents', {
                        all:true, 
                        from: _from.toSQLDate(), 
                        to: _to.toSQLDate(),
                        ...this.filterOptions
                        })
                    .then(() => {
                        this.holidays = this.months[current].year
                        events = events
                            .concat( this.calendar_events.filter(filter_month).filter(filter_sponsor) )
                            .concat( this.family_events.filter(filter_month).filter(filter_sponsor) )
                            .concat( this.holidays.filter(filter_month) )
                        putCalendarPage(
                            this.months[current].month,
                            this.months[current].year,
                            events,
                            {
                                events_font_size: this.events_font_size,
                                event_lines_limit: this.event_lines_limit,
                                events_long_line: this.events_long_line,
                                cut_very_long_lines: this.cut_very_long_lines,
                                font: this.font,
                                force_bold_content: this.force_bold_content,
                                trim_calendar_events: this.trim_calendar_events,
                                trim_family_events: this.trim_family_events,
                                char_spacing: this.char_spacing,
                                sort_by: this.sort_by,
                                events_at_bottom: this.events_at_bottom
                            }
                        )
                        current++
                        if(current<this.months.length){
                            process()
                        }else{
                            saveAs(
                                getPdf('export.pdf')
                            )
                            this.loading = null
                        } 
                    })
            }
            createDoc({orientation: 'l'})
            process()
        }
    },
    mixins: [authMixin],
    components: {
        MultiSelect, Dropdown, InputSwitch, SelectButton
    },
    mounted(){
        this.subscription = this.$store.subscribe((mutation, state) => {

            if(mutation.type == 'setUserInfo'){
                 
                this._load()

            }

        })
    },
    beforeUnmount(){
        this.subscription()
    },
    beforeMount(){
        this._load()
    }
}
</script>

<style lang="scss">
.vc{
    &-container{
        max-width:100%;
    }
    &-container, &-pane-container, &-pane-layout{
        height:100% !important;
    }
    &-pane-layout{
        .vc-pane{
            display:flex;
            flex-direction:column;
            .vc-header{
            flex-grow:0;
            }
            .vc-weeks{
            flex-grow:1;
            grid-auto-rows: 14.28%;
                .vc-day{
                    .day-label{
                        $size: 25px;
                        position: absolute;
                        right: 15px;
                        z-index: -1;
                        top: 0px;
                        width: $size;
                        height: $size;
                        font-size: $size;
                        line-height: $size;
                        word-spacing: 0;
                        color: rgba(0,0,0,0.5);
                        text-align: right;
                    }
                    .day-schedule{
                        font-size:12px;
                        overflow-x:hidden;
                        overflow-y:auto;
                        p{ margin-bottom:0; }

                        position: absolute;
                        top: 0;
                        left: 0;
                        color: rgba(0,0,0,1);
                        max-width: 100%;
                        max-height: 100%;

                        &::-webkit-scrollbar {
                          display: none;
                        }
                        -ms-overflow-style: none;
                        scrollbar-width: none;

                    }
                }
            }
        }
    }
}
.multiselect{
    max-width:100%;
    width:100%;
}
[role=events_label]{
    font-size: 0.7rem;
}
.p-inputswitch{
height:24px;
}
.p-autocomplete-input, .p-dropdown{
height: 30px;
}
.p-dropdown-label{
justify-content: left;
align-items: center;
display: inline-flex;
}
.p-button{
&, & > *{
height: 25px;
}
}
</style>