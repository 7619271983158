export const sortByOrder = (a, b) => {
    let orderA = a.order_id
    let orderB = b.order_id
    const sorting = (a, b) => {
        if (a < b) {
            return -1;
        }
        if (a > b) {
            return 1;
        }
        return 0;
    }
    let sort = 0
    sort += sorting(orderA, orderB)
    return sort
}