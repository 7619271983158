import { createStore } from 'vuex'
import auth from './auth'
import logger from './logger'
import events from './events'
import admins from './admins'
import family from './family'
import sponsors from './sponsors'
import canvasser from './canvasser'
import orders from './orders'

const store = createStore({
    modules: {
        auth,
        logger,
        events,
        admins,
        family,
        sponsors,
        canvasser,
        orders
    },
    //these actions are general actions. think of them as 'root' actions
    //they might be called in any of the modules above
    actions: {
        load({dispatch, commit}){
            commit('signedIn', true)
            dispatch('syncUserInfo')
        },
        unload({dispatch, commit}){
            commit('setUserInfo', {})
            commit('signedIn', false)
        }
    }
})

export default store