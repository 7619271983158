<template>
    <span>{{label}}</span>
</template>

<script>

export default {
    data: function () {
        return {
            label: null
        }
    },
    beforeMount() {
        if(this.params.value == null)
            this.label = ''
        if(this.params.value === true)
            this.label = 'Yes'
        if(this.params.value === false)
            this.label = 'No'
    },
    methods: {
        refresh(params) {
            if(params.value == null)
                this.label = ''
            if(params.value === true)
                this.label = 'Yes'
            if(params.value === false)
                this.label = 'No'
        }
    }
}
</script>

<style>
</style>