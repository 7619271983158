import remote from '../remote'

const state = () => {
    return {
        list: []
    }
}

const getters = {
    getFamilies(state){
        return state.list
    }
}

const mutations = {
    setFamiliesList(state, list){
        state.list = list
    }
}

const actions = {
    fetchFamilies({rootState, dispatch, commit}, _family_name = null, _sponsor_id = null){
        return new Promise(async (resolve, reject) => {
            if(/(admin|sponsor|canvasser)/.test(rootState.auth.user.role)){
                let { data, error } = await remote.rpc('list_families', { _family_name, _sponsor_id })
                if(error == null){
                    commit('setFamiliesList', data)
                    resolve(data)
                }
            }else reject('unauthorized')
        })
    },
    async setFamilyDetails({rootState, dispatch, commit}, family_details){
        if(/(admin|sponsor|canvasser)/.test(rootState.auth.user.role)){
            let { data, error } = await remote.rpc('set_family_details', { options: family_details })
            if(error == null){
                dispatch('fetchFamilies')
            }
        }
    },
    async setFamilyMember({rootState, dispatch, commit}, new_member){
        if(/(admin|sponsor|canvasser)/.test(rootState.auth.user.role)){
            let { data, error } = await remote.rpc('set_profile', { options: new_member })
            if(error == null){
            }
        }
    }
}

export default {
    state, getters, mutations, actions
}