import {generateID} from '../utils'

const state = () => {
    return {
        logs: []
    }
}

const getters = {
    getLogs(state){
        return state.logs
    },
    getLog(state){
        return (id) => {
            return state.logs.filter((item) => id == item.id)[0]
        }
    },
    getLogsByType(state){
        return (type) => {
            return state.logs.filter((item) => type == item.type)
        }
    },
    getLogsByCategory(state){
        return (category) => {
            return state.logs.filter((item) => category == item.category)
        }
    },
    getUnseenLogs(state){
        return (type, category) => {
            return state.logs
                .filter((item) => item.shown == false)
                .filter((item) => item.type == type || typeof type == 'undefined')
                .filter((item) => item.category == category || typeof category == 'undefined')
        }
    }
}

const mutations = {
    setLog(state, {type, category, title, message}){
        state.logs = [...state.logs, {id: generateID(), type, category, title, message, shown: false}]
    },
    setError(state, {category, title, message}){
        state.logs = [...state.logs, {id: generateID(), type: 'error', category, title, message, shown: false}]
    },
    setWarning(state, {category, title, message}){
        state.logs = [...state.logs, {id: generateID(), type: 'warning', category, title, message, shown: false}]
    },
    setInfo(state, {category, title, message}){
        state.logs = [...state.logs, {id: generateID(), type: 'info', category, title, message, shown: false}]
    },
    logShown(state, id){
        let logs = state.logs
        for(let log of logs){
            if(log.id == id){
                log.shown = true
            }
        }
        state.logs = [...logs]
    }
}

const actions = {
    setLog({commit}, {type, category, title, message}){
        commit('setLog',{type, category, title, message})
    },
    setError({commit}, {category, title, message}){
        commit('setError',{category, title, message})
    },
    setWarning({commit}, {category, title, message}){
        commit('setWarning',{category, title, message})
    },
    setInfo({commit}, {category, title, message}){
        commit('setInfo',{category, title, message})
    },
    logShown({commit}, id){
        commit('logShown',id)
    }
}

export default {
    state, getters, mutations, actions
}