<template>
    <span>{{label}}</span>
</template>

<script>
export default {
    data: function () {
        return {
            label: null
        }
    },
    methods: {
        buildLabel(params){
            if(params.value == 'B'){ this.label = 'Birthday' }
            else if(params.value == 'A'){ this.label = 'Anniversary' }
            else if(params.value == 'M'){ this.label = 'Memorial' }
        },
        refresh(params) {
            this.buildLabel(params)
        }
    },
    beforeMount() {
        this.buildLabel(this.params)
    }
}
</script>

<style>
</style>