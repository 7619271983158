<template>
    <div class="event-type-editor d-flex flex-column justify-content-between" ref="container">
        <a class="btn btn-info m-2" role="button" @click="setEvent('B')" :disable="event_type == 'B'">Birthday</a>
        <a class="btn btn-info m-2" role="button" @click="setEvent('A')" :disable="event_type == 'A'">Anniversary</a>
        <a class="btn btn-info m-2" role="button" @click="setEvent('M')" :disable="event_type == 'M'">Memorial</a>
    </div>
</template>

<script>
import { nextTick } from 'vue'

export default {
    data() {
        return {
            event_type: null
        };
    },
    methods: {
        getValue() {
            return this.event_type
        },
        isPopup() {
            return true
        },
        setEvent(event) {
            this.event_type = event
            this.finish()
        },
        finish() {
            this.params.api.stopEditing()
        }
        //isCancelBeforeStart() {
        //    return false
        //},
        //isCancelAfterEnd() {
        //    return true
        //}
    },
    mounted() {
        this.event_type = this.params.value
        nextTick(() => {
            this.$refs.container.focus()
        })
    }
}
</script>

<style>
.event-type-editor{
    width:200px;
    height:160px;
    outline:none;
}
</style>

